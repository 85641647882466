import React, { useRef, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { HandThumbsUp, HandThumbsDown, Check, CheckCircleFill, QuestionCircle } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Explanation from './Ridella/Explanation';
import { iconMapping } from './Ridella/IconMapping';
import { useLocation, useNavigate } from 'react-router-dom';
import { faArrowLeft, faCircleInfo, faCircleExclamation, faFaceDisappointed } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import CompletedModal from './CompletedModal';
import { updatePlayHistory, getGameStatus } from './playHistoryUtil';
import { getStoredUserId } from './Userid';
import lightbulbOnLight from '../assets/lightbulb-on-light.png';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import RatingAlert from './RatingAlert';
import { useNewYorkDate, checkIfToday } from './DateTime';
import { checkAndroidAndLogEvent } from './FirebaseEvent';

const InfoModal = ({ isOpen, onClose }) => {
    return (
        <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
            <div className="modal-box">
                <h2 className="text-xl font-bold mb-4">Can you solve the riddle in four tries?</h2>
                <p className="text-lg">Start with a partially complete riddle</p>
                <p className="text-lg">Each incorrect guess reveals another clue</p>
                <p className="text-lg">You have one chance to solve once the entire riddle is revealed!</p>
                <div className="modal-action">
                    <button onClick={onClose} className="btn btn-primary text-white text-xl">Close</button>
                </div>
            </div>
        </div>
    );
};

const Ridella = () => {
    const inputRef = useRef(null);
    const [data, setData] = useState({});
    const [message, setMessage] = useState('');
    const [gameOver, setGameOver] = useState(false);
    const [totalGuesses, setTotalGuesses] = useState(0);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [user, setUser] = useState(null);
    const [showDislikeOptions, setShowDislikeOptions] = useState(false);
    const [dislikedReason, setDislikedReason] = useState(null);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasGuesses, setHasGuesses] = useState(false);
    const [icon, setIcon] = useState('');
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const handleShowExplanation = () => setShowExplanation(true);
    const handleCloseExplanation = () => setShowExplanation(false);
    const [shareText, setShareText] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get('date');
    const [currentDate, setCurrentDate] = useState(null);
    const nyDate = useNewYorkDate();
    const [userId, setUserId] = useState(null);
    const [shouldSendGameData, setShouldSendGameData] = useState(false);
    const [isToday, setIsToday] = useState(false);
    const [guesses, setGuesses] = useState([]);
    const [dataLoadFailed, setDataLoadFailed] = useState(false);

    useEffect(() => {
        const getProgress = async () => {
            const { value } = await Preferences.get({ key: 'progress' });
            const progress = value ? JSON.parse(value) : null;
            setHasGuesses(progress && progress.guesses && progress.guesses.length > 0);
            if (progress && progress.guesses) {
                setGuesses(progress.guesses);
            }
        };
        getProgress();
    }, []);

    useEffect(() => {
        let newDate;
        if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
            newDate = dateParam;
        } else {
            newDate = nyDate;
        }
        setCurrentDate(newDate);
        setIsToday(checkIfToday(newDate));
    }, [dateParam, nyDate]);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/', { state: { date: currentDate } });
    };

    const resetGameState = useCallback((newDate) => {
        setData({});
        setMessage('');
        setGameOver(false);
        setTotalGuesses(0);
        setButtonDisabled(false);
        setStartTime(null);
        setUser(null);
        setShowDislikeOptions(false);
        setDislikedReason(null);
        setFeedbackSubmitted(false);
        setIsLoading(true);
        setIcon('');
        setIsInfoModalOpen(false);
        setIsModalOpen(false);
        setShowExplanation(false);
        setShareText('');
        setCurrentDate(newDate);
        setShouldSendGameData(false);
        setHasGuesses(false);
        setGuesses([]);
    }, []);

    const handleDateChange = useCallback((newDate) => {
        resetGameState(newDate);
        navigate(`/ridella?date=${newDate}`);
    }, [resetGameState, navigate]);

    useEffect(() => {
        const fetchUserId = async () => {
            const id = await getStoredUserId();
            setUserId(id);
        };
        fetchUserId();
    }, []);

    const sendGameData = useCallback(async (dateString, userId, value, wasCorrect) => {
        if (!dateString || !userId) return;
        try {
            const source = ['ios', 'android'].includes(Capacitor.getPlatform()) ? Capacitor.getPlatform() : 'web';
          const payload = {
            date: dateString,
            game: "ridella",
            userid: userId,
            guess: value === "" ? "null" : value,
            wasCorrect: wasCorrect,
            source: source
          };    
          await axios.post('https://nu5koio7l8.execute-api.us-east-1.amazonaws.com/prod', payload);
        } catch (error) {
          console.error("Error triggering Lambda:", error);
        }
    }, []);

    const fetchUser = useCallback(async () => {
        try {
            const currentUser = null;
            setUser(currentUser);
        } catch (error) {
            console.log('User data not fetched: ', error);
        }
    }, []);

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`https://9571p9igid.execute-api.us-east-1.amazonaws.com/prod?date=${currentDate}`);

            setData(response.data);
            setStartTime(Date.now());
            setIsLoading(false);
            const progress = await Preferences.get({ key: 'progress' });
            const iconName = response.data.icon;
            setIcon(iconMapping[iconName])
            if (progress.value) {
                const storedData = JSON.parse(progress.value);
                if (storedData.date !== response.data.date) {
                    await Preferences.remove({ key: 'progress' });
                    setHasGuesses(false);
                    setGuesses([]);
                } else {
                    setGuesses(storedData.guesses || []);
                }
            }
            const status = await getGameStatus(currentDate, 'ridella');
            if (!status) {
                updatePlayHistory(currentDate, 'ridella', 'inprogress');
                setShouldSendGameData(true);
            }

            const riddleStatsStr = await Preferences.get({ key: 'riddleStats' });
            const riddleStats = riddleStatsStr.value ? JSON.parse(riddleStatsStr.value) : {};
            if (riddleStats[response.data.date]) {
                setGameOver(true);
                const emojiString = createEmojiString(riddleStats[response.data.date].guesses, true, response.data.date);
                setShareText(emojiString);
                setIsModalOpen(true);
                setTotalGuesses(riddleStats[response.data.date].guesses);
                if (riddleStats[response.data.date].solved) {
                    setMessage({ text: 'Correct!' });
                } else {
                    setMessage({ text: `Out of Guesses` });
                }
                showRiddleStats();
            }            
        } catch (error) {
            console.error('There was an error!', error);
            setMessage({ text: 'AWS currently has an outage. Please check back later.' });
            setDataLoadFailed(true);
            setIsLoading(false);
        }
    }, [currentDate]);

    useEffect(() => {
        fetchUser();
        if (currentDate) {
            fetchData();
        }
    }, [fetchUser, fetchData, currentDate]);

    useEffect(() => {
        if (userId && !isLoading && shouldSendGameData) {
            sendGameData(currentDate, userId);
            setShouldSendGameData(false);
        }
    }, [userId, isLoading, shouldSendGameData, sendGameData, currentDate]);

    useEffect(() => {
        document.title = "Ridella";
    }, []);

    useEffect(() => {
        const fetchRiddleStats = async () => {
            const riddleStatsStr = await Preferences.get({ key: 'riddleStats' });
            let riddleStats = riddleStatsStr.value ? JSON.parse(riddleStatsStr.value) : {};
            if (user !== null) {
                try {
                    const response = await fetch(`https://e50k0bjhjl.execute-api.us-east-1.amazonaws.com/prod?username=${user.username}`);
                    const data = await response.json();
                    const updatedRiddleStats = { ...riddleStats, ...data.riddleStats };
                    await Preferences.set({
                        key: 'riddleStats',
                        value: JSON.stringify(updatedRiddleStats)
                    });
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };
        fetchRiddleStats();
    }, [user]);

    useEffect(() => {
        const likedRiddle = async () => {
            if (dislikedReason !== null) {
                try {
                    await axios.post('https://wbhsffi011.execute-api.us-east-1.amazonaws.com/prod', {
                        date: data.date,
                        userid: userId,
                        dislikedReason: dislikedReason
                    });
                } catch (error) {
                    console.error('There was an error!', error);
                }
            }
        };

        likedRiddle();
    }, [dislikedReason, data.date, userId]);

    async function storeProgress(value, timeTaken, dateString, wasCorrect) {
        var data = {
            'guesses': [],
            'timeTaken': 0,
            'date': dateString,
            'wasCorrect': wasCorrect
        };
        const storedData = await Preferences.get({ key: 'progress' });
        if (storedData.value) {
            data = JSON.parse(storedData.value);
            if (data.date === dateString) {
                data.guesses.push(value);
                data.timeTaken += timeTaken;
                data.wasCorrect = wasCorrect;
            } else {
                data = {
                    'guesses': [value],
                    'timeTaken': timeTaken,
                    'date': dateString,
                    'wasCorrect': wasCorrect
                };
            }
        } else {
            data = {
                'guesses': [value],
                'timeTaken': timeTaken,
                'date': dateString,
                'wasCorrect': wasCorrect
            };
        }
        await Preferences.set({
            key: 'progress',
            value: JSON.stringify(data)
        });
        setGuesses(data.guesses);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const value = inputRef.current.value.trim().toLowerCase();
        setTotalGuesses(totalGuesses + 1);
        setHasGuesses(true);
        const wasCorrect = data.answers.some(answer => value.includes(answer.toLowerCase()));
        const timeTaken = (Date.now() - startTime) / 1000;
        await storeProgress(value, timeTaken, currentDate, wasCorrect);
        sendGameData(currentDate, userId, value, wasCorrect)
        if (wasCorrect) {
            setMessage({ text: 'Correct!' });
            setGameOver(true);
            await storeRiddleStats(totalGuesses, timeTaken, true);
            showRiddleStats();
            const emojiString = createEmojiString(totalGuesses + 1, true, currentDate);
            setShareText(emojiString);
            setIsModalOpen(true);
            updatePlayHistory(currentDate, 'ridella', 'completed');
            checkAndroidAndLogEvent("ridella")
        } else {
            setMessage({ text: 'Try Again!' });
            inputRef.current.value = "";
            if (totalGuesses >= 3) {
                setMessage({ text: 'Out of Guesses' });
                setGameOver(true);
                await storeRiddleStats(totalGuesses, timeTaken, false);
                showRiddleStats();
                const emojiString = createEmojiString(4, false, currentDate);
                setShareText(emojiString);
                updatePlayHistory(currentDate, 'ridella', 'completed');
            }
            setTimeout(() => {
                setButtonDisabled(false);
            }, 1500);
        }
    };

    const storeRiddleStats = async (guesses, timeTaken, wasCorrect) => {
        const riddleStatsStr = await Preferences.get({ key: 'riddleStats' });
        const riddleStats = riddleStatsStr.value ? JSON.parse(riddleStatsStr.value) : {};
        if (!riddleStats[data.date]) {
            riddleStats[data.date] = {
                guesses: guesses + 1,
                timeTaken: timeTaken,
                solved: wasCorrect
            };
            await Preferences.set({
                key: 'riddleStats',
                value: JSON.stringify(riddleStats)
            });
        }
        if (user) {
            console.log('User is logged in:', user);
            try {
                await axios.post('https://mvoztqmfje.execute-api.us-east-1.amazonaws.com/prod', {
                    username: user.username,
                    date: data.date,
                    guesses: guesses + 1,
                    solved: wasCorrect,
                    timeTaken: timeTaken
                });
            } catch (error) {
                console.error('There was an error!', error);
            }
        } else {
            console.log('User is not logged in');
        }
    }

    const showRiddleStats = async () => {
        const { value } = await Preferences.get({ key: 'riddleStats' });
        const riddleStats = value ? JSON.parse(value) : {};
        let guessCounts = [0, 0, 0, 0];
        let notSolvedCount = 0;
        Object.values(riddleStats).forEach(stat => {
            if (stat.solved) {
                guessCounts[stat.guesses - 1]++;
            } else {
                notSolvedCount++;
            }
        });
        const data = ['1', '2', '3', '4'].map((guess, i) => ({ name: guess, count: guessCounts[i] }));
        data.push({ name: "NS", count: notSolvedCount });
    };

    useEffect(() => {
        const getStoredData = async () => {
            const { value } = await Preferences.get({ key: 'progress' });
            const storedData = value ? JSON.parse(value) : null;
            if (storedData && storedData.date === data.date) {
                setTotalGuesses(storedData.guesses.length);
                setGuesses(storedData.guesses);
            }
        };
        getStoredData();
    }, [data.date]);

    const renderLines = () => {
        if (isLoading) {
            return (
                <div className="flex flex-col items-center justify-center">
                    <h2 className="text-2xl font-bold mb-4">Loading</h2>
                    <span className="loading loading-dots loading-lg"></span>
                </div>
            );
        } else if (dataLoadFailed) {
            return (
                <div className="flex flex-col items-center justify-center text-center">
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">There was an error loading the game.</h2>
                    <FontAwesomeIcon icon={faFaceDisappointed} className="text-4xl mt-4" />
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">Please try again later.</h2>
                </div>
            );
        }
        const lines = [data.firstLine, data.secondLine, data.thirdLine, data.fourthLine];
        const revealLines = gameOver ? 4 : totalGuesses + 1;
        return lines.map((line, index) => (
            <p key={index} className={`${revealLines > index ? '' : 'blur-sm select-none'} text-xl text-center leading-loose transition-all duration-300`}>
                {line}
            </p>
        ));
    };

    const createEmojiString = (guesses, solved, date) => {
        const greenSquare = "🟩";
        const greySquare = "⬜";
        const yellowSquare = "🟨";
        let emojiString = `Ridella ${date}\n\n💡 `;
        for (let i = 1; i <= 4; i++) {
            if (i === guesses && solved === true) {
                emojiString += greenSquare + " ";
            } else {
                emojiString += (i < guesses ? yellowSquare : greySquare) + " ";
            }
        }
        emojiString += "\n\nhttps://ridella.xyz";
        return emojiString;
    };    

    return (
        <div className="container mx-auto rajdhani-font">
            <div className="pt-2 shadow-b mb-2 flex justify-between items-center rajdhani-font">
                <button onClick={handleBackClick} className="btn btn-ghost">
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </button>
                <div className="flex items-center justify-center flex-grow">
                    <img src={lightbulbOnLight} alt="Ridella" className="h-8 mr-2 dark:invert" />
                    <span className="font-bold text-xl">Ridella</span>
                </div>
                <button onClick={() => setIsInfoModalOpen(true)} className="ml-auto mr-2">
                    <FontAwesomeIcon icon={faCircleInfo} className="text-3xl" />
                </button>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full max-w-md">
                    {renderLines()}
                </div>
                {!gameOver && (
                    <form onSubmit={handleSubmit} className="w-full max-w-md">
                        <InputSection inputRef={inputRef} disabled={gameOver || isButtonDisabled} />
                    </form>
                )}
                {gameOver && (
                    <div className="flex items-center justify-center mt-5">
                        <div className={`flex items-center p-3 rounded-full ${message.text === "Correct!" ? "bg-success text-success-content" : "bg-warning text-error-content"
                            }`}>
                            <span className="mr-2">
                                {message.text === "Correct!" ?
                                    <CheckCircleFill className="w-6 h-6" /> :
                                    <FontAwesomeIcon icon={faCircleExclamation} className="w-6 h-6 mt-1" />}
                            </span>
                            <span>{message.text}</span>
                        </div>
                    </div>
                )}
                {!hasGuesses && !gameOver && (
                    <div className="card w-[300px] max-w-md bg-base-100 shadow-xl mt-5 no-hover bg-secondary border border-primary p-4">
                        <div className="card-body items-center text-center">
                            <p className="text-lg dark:text-black">Solve the riddle in four tries.</p>
                            <button
                                className="btn bg-white border-primary text-primary h-auto min-h-0 py-2"
                                onClick={() => setIsInfoModalOpen(true)}
                            >
                                <QuestionCircle size={20} className="mr-1"/> <span className="text-md">How to Play</span>
                            </button>
                        </div>
                    </div>
                )}
                {hasGuesses && (
                    <div className="card w-[300px] max-w-md bg-secondary border-2 border-primary mt-5 p-2 no-hover dark:text-black">
                        <div className="flex flex-col h-full">
                            <div className="flex justify-between items-start">
                                <p className="text-lg">Guesses:</p>
                                <div className="text-right">
                                    {`${guesses.length}/4`}
                                </div>
                            </div>
                            <div className={`flex-grow ${message.status || ''}`}>
                                <div>
                                    {guesses.map((guess, index) => (
                                        <p key={index}>{`${index + 1}. ${guess || '(blank)'}`}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {gameOver && (
                    <>
                        <div className="flex items-center justify-between w-full max-w-md mt-5">
                            <div className="w-1/2 text-center">
                                <p className="text-sm">Answer:</p>
                                <p className="font-bold text-2xl">{data.answers[0]}</p>
                            </div>
                            <div className="w-1/2 flex justify-center">
                                <FontAwesomeIcon icon={icon} className="text-6xl mr-8" />
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <div className="mr-2">
                                <Explanation data={data} show={showExplanation} handleShow={handleShowExplanation} handleClose={handleCloseExplanation} />
                            </div>
                        </div>
                        {isToday && (
                        <div className="w-full max-w-md mt-5">
                            <div className="items-center text-center">
                                {feedbackSubmitted ? (
                                    <div className="flex flex-col items-center">
                                        <Check size={52} className="text-success" />
                                        <p className="mt-2">Thanks for the feedback!</p>
                                    </div>
                                ) : (
                                    <>
                                        <p>Did you like today's riddle?</p>
                                        <div className="flex justify-center mt-2">
                                            <button className="btn btn-sm btn-outline btn-success rounded-full mr-2 w-28" onClick={() => { setDislikedReason("Liked"); setFeedbackSubmitted(true); }}>
                                                <HandThumbsUp className="mr-1" /> Like
                                            </button>
                                            <button className="btn btn-sm btn-outline btn-error rounded-full w-28" onClick={() => setShowDislikeOptions(true)}>
                                                <HandThumbsDown className="mr-1" /> Dislike
                                            </button>
                                        </div>
                                        {showDislikeOptions && (
                                            <div className="mt-4">
                                                <p>Why didn't you like it?</p>
                                                <div className="flex flex-wrap justify-center mt-2">
                                                    <button className="btn btn-sm btn-outline btn-primary m-1" onClick={() => { setDislikedReason('Too easy'); setFeedbackSubmitted(true); }}>Too easy</button>
                                                    <button className="btn btn-sm btn-outline btn-primary m-1" onClick={() => { setDislikedReason('Too hard'); setFeedbackSubmitted(true); }}>Too hard</button>
                                                    <button className="btn btn-sm btn-outline btn-primary m-1" onClick={() => { setDislikedReason("Didn't accept my answer"); setFeedbackSubmitted(true); }}>Didn't accept my answer</button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        )}
                    </>
                )}
            </div>
            <CompletedModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} game="ridella" shareText={shareText} currentDate={currentDate} onDateChange={handleDateChange}/>
            <InfoModal isOpen={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)} />
            {gameOver && <RatingAlert/>}
        </div>
    );
};

function InputSection({ inputRef, disabled }) {

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            Keyboard.setAccessoryBarVisible({ isVisible: false });
            Keyboard.setScroll({ isDisabled: true });
            return () => {
                Keyboard.setScroll({ isDisabled: false });
            };
        }
    }, []);

    return (
        <div className="flex items-center justify-center space-x-4 my-5">
            <input
                type="text"
                ref={inputRef}
                disabled={disabled}
                maxLength={20}
                className="input input-bordered w-[200px] text-xl"
            />
            <button
                type="submit"
                disabled={disabled}
                className="btn btn-outline btn-primary rounded-full w-32 normal-case text-lg font-bold"
            >
                Solve
            </button>
        </div>
    );
}

export default Ridella;
