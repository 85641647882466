import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@awesome.me/kit-636b1434d3/icons/classic/regular';

const CrosswordInfo = ({ isOpen, onClose, crosswordData }) => {
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal')) {
            onClose();
        }
    };

    return (
        <div className={`modal ${isOpen ? 'modal-open' : ''}`} onClick={handleOutsideClick}>
            <div className="modal-box max-w-xl relative">
                <button onClick={onClose} className="btn btn-ghost btn-lg btn-circle absolute right-2 top-2">
                    <FontAwesomeIcon icon={faXmark} className="text-2xl" />
                </button>
                <h2 className="text-2xl font-bold text-center mb-4">Crossword Information</h2>
                <div className="flex flex-col items-center">
                    {crosswordData && (
                        <>
                            <p className="text-xl mb-2"><strong>Title:</strong> {crosswordData.title}</p>
                            <p className="text-xl"><strong>Author:</strong> {crosswordData.author}</p>
                            <p className="text-xl">{crosswordData.copyright}</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CrosswordInfo;