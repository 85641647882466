import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Device } from '@capacitor/device';

export const checkAndroidAndLogEvent = async (gameName) => {
  try {
    const info = await Device.getInfo();
    
    if (info.platform === 'android') {
      console.log('User is on an Android device');
      
      // Log event to Firebase Analytics
      await FirebaseAnalytics.logEvent({
        name: 'game_completed',
        params: { game: gameName },
      });
      
      console.log(`Event logged to Firebase Analytics for game: ${gameName}`);
    } else {
      console.log('User is not on an Android device');
    }
  } catch (error) {
    console.error('Error checking device or logging event:', error);
  }
};
