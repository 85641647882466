import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faXmark } from '@awesome.me/kit-636b1434d3/icons/classic/regular';

import clique1Gif from '../assets/clique1.gif';
import clique2Gif from '../assets/clique2.gif';
import clique3Gif from '../assets/clique3.gif';
import clique4Gif from '../assets/clique4.gif';

const InfoModal = ({ isOpen, onClose }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = 4;

    const slides = [
        { gif: clique1Gif, text: "Fill in words by entering letters from the bank below." },
        { gif: clique2Gif, text: "Earn hints by finding non-theme words." },
        { gif: clique3Gif, text: "Win by finding words that belong to the theme." },
        { gif: clique4Gif, text: "The theme is shaded in purple from top to bottom." }
    ];

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % totalSlides);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal')) {
            onClose();
        }
    };

    return (
        <div className={`modal ${isOpen ? 'modal-open' : ''}`} onClick={handleOutsideClick}>
            <div className="modal-box max-w-xl relative">
                <button onClick={onClose} className="btn btn-ghost btn-lg btn-circle absolute right-2 top-2">
                    <FontAwesomeIcon icon={faXmark} className="text-2xl" />
                </button>
                <h2 className="text-2xl font-bold text-center mb-4">How To Play Clique</h2>
                <div className="carousel w-full">
                    {slides.map((slide, index) => (
                        <div key={index} className={`carousel-item relative w-full flex flex-col items-center ${index === currentSlide ? '' : 'hidden'}`}>
                            <img src={slide.gif} className="w-full max-w-[400px]" alt={`Clique gameplay ${index + 1}`} />
                            <div className="h-20 mt-4 w-full flex items-center justify-center">
                                <p className="text-xl text-center">{slide.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center items-center mt-4">
                    <button onClick={prevSlide} className="btn btn-ghost btn-circle">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <div className="flex justify-center mx-2">
                        {slides.map((_, index) => (
                            <button
                                key={index}
                                className={`btn btn-circle btn-xs mx-1 ${currentSlide === index ? 'btn-primary' : 'btn-outline'}`}
                                onClick={() => setCurrentSlide(index)}
                            >
                                {/* Empty button */}
                            </button>
                        ))}
                    </div>
                    <button onClick={nextSlide} className="btn btn-ghost btn-circle">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;