import React, { useState } from 'react';
import '../App.css';

function TheWeek() {

    const [selectedButton, setSelectedButton] = useState(null);

    const handleButtonClick = (buttonIndex) => {
        setSelectedButton(buttonIndex);
    };

    const Button = ({ index, children }) => (
        <button 
            className={`btn btn-outline justify-start rounded-full ${selectedButton === index ? 'border-primary' : 'border-gray-300'}`}
            onClick={() => handleButtonClick(index)}
        >
            {children}
        </button>
    );

    return (
        <div className="container mx-auto my-8 px-10">
            <div className="card bg-base-100 shadow-xl mb-8 border border-gray-300">
                <div className="card-body">
                    <p className="text-xl md:text-xl font-bold mb-5">What is the capital of France?</p>
                    <div className="grid grid-cols-1 gap-4">
                        <Button index={0}>A. Paris</Button>
                        <Button index={1}>B. Madrid</Button>
                        <Button index={2}>C. Rome</Button>
                        <Button index={3}>D. Berlin</Button>
                    </div>
                    <div className="flex mt-4">
                        <button className="btn btn-secondary w-auto">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TheWeek;
