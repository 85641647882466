import { useEffect, useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { Dialog } from '@capacitor/dialog';
import { Browser } from '@capacitor/browser';
import { useNavigate } from 'react-router-dom';
import { InAppReview } from '@capacitor-community/in-app-review';

const RatingAlert = () => {
    const navigate = useNavigate();
    // console.log("rating alert")
    const handleClose = useCallback(async () => {
        await Preferences.set({
            key: 'feedbackAlert',
            value: JSON.stringify({ lastPromptDate: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }) })
        });
    }, []);

    const handleRateApp = useCallback(async () => {
        try {
            await InAppReview.requestReview();
        } catch (error) {
            const storeUrl = Capacitor.getPlatform() === 'ios'
                ? 'https://apps.apple.com/us/app/puzpop/id6670177208'
                : 'https://play.google.com/store/apps/details?id=com.puzpop.app';
            await Browser.open({ url: storeUrl });
        }
        handleClose();
    }, [handleClose]);

    const showFeedbackDialog = useCallback(async () => {
        const { value } = await Dialog.confirm({
            title: 'We\'re sorry to hear that.',
            message: 'We would appreciate your feedback to help us improve.',
            okButtonTitle: 'Provide Feedback',
            cancelButtonTitle: 'Not Now'
        });
        if (value) {
            navigate('/feedback');
        }
        handleClose();
    }, [navigate, handleClose]);

    const showRateAppDialog = useCallback(async () => {
        const { value } = await Dialog.confirm({
            title: 'Help Make PuzPop Even Better!',
            message: 'Consider leaving a review. Share your thoughts and help us deliver the features you want most.',
            okButtonTitle: 'Leave a review',
            cancelButtonTitle: 'Remind me later'
        });

        if (value) {
            handleRateApp();
        } else {
            handleClose();
        }
    }, [handleRateApp, handleClose]);

    const showInitialDialog = useCallback(async () => {
        const { value } = await Dialog.confirm({
            title: 'Are you enjoying PuzPop?',
            message: '',
            okButtonTitle: 'Yes',
            cancelButtonTitle: 'No'
        });

        if (value) {
            showRateAppDialog();
        } else {
            showFeedbackDialog();
        }
    }, [showRateAppDialog, showFeedbackDialog]);

    const checkAndShowFeedbackAlert = useCallback(async () => {
        if (Capacitor.getPlatform() === 'web') return;

        const { value: feedbackAlertData } = await Preferences.get({ key: 'feedbackAlert' });
        const parsedFeedbackAlertData = feedbackAlertData ? JSON.parse(feedbackAlertData) : null;
        // console.log(parsedFeedbackAlertData)
        if (parsedFeedbackAlertData && parsedFeedbackAlertData.dontAskAgain) return;

        const { value: playHistoryData } = await Preferences.get({ key: 'playHistory' });
        const playHistory = playHistoryData ? JSON.parse(playHistoryData) : {};

        const dates = Object.keys(playHistory);
        if (dates.length < 2) return;

        const firstPlayDate = new Date(dates[0]);
        const lastPlayDate = new Date(dates[dates.length - 1]);

        if (lastPlayDate.getTime() === firstPlayDate.getTime()) return;

        const lastCompletedGame = playHistory[dates[dates.length - 1]];
        const hasCompletedGame = Object.values(lastCompletedGame).some(status => status === 'completed');

        if (!hasCompletedGame) return;

        if (parsedFeedbackAlertData && parsedFeedbackAlertData.lastPromptDate) {
            const lastPromptDate = new Date(parsedFeedbackAlertData.lastPromptDate);
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

            if (lastPromptDate > oneMonthAgo) return;
        }

        showInitialDialog();
    }, [showInitialDialog]);

    useEffect(() => {
        checkAndShowFeedbackAlert();
    }, [checkAndShowFeedbackAlert]);

    return null;
};

export default RatingAlert;