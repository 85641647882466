import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleInfo, faTimes, faFaceDisappointed } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import CompletedModal from './CompletedModal';
import lemmatize from 'wink-lemmatizer';
import { getStoredUserId } from './Userid';
import { updatePlayHistory, getGameStatus } from './playHistoryUtil';
import proximityImage from '../assets/proximity.png';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { useNewYorkDate } from './DateTime';
import { checkAndroidAndLogEvent } from './FirebaseEvent';
import RatingAlert from './RatingAlert';

const InfoModal = ({ isOpen, onClose }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={`modal ${isOpen ? 'modal-open' : ''}`} onClick={handleOverlayClick}>
            <div className="modal-box">
                <button 
                    onClick={onClose} 
                    className="btn btn-ghost btn-circle absolute right-2 top-2"
                >
                    <FontAwesomeIcon icon={faTimes} className="text-lg" />
                </button>
                <h2 className="text-xl font-bold mb-4">Can you find the target word?</h2>
                <p>Guess nouns from the predefined list.</p>
                <p>Words are ranked by AI in order of semantic similarity to the target word.</p>
                <p>This means that words are ordered by their context and meaning, not their spelling or sound.</p>
                <p>The closer you are, the lower the rank of your guess.</p>
            </div>
        </div>
    );
};

const Top100Modal = ({ isOpen, onClose, topWords, calculateWidthAndColor, totalRanks }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={`modal ${isOpen ? 'modal-open' : ''}`} onClick={handleOverlayClick}>
            <div className="modal-box max-w-3xl max-h-[80vh] overflow-y-auto relative">
                <button 
                    onClick={onClose} 
                    className="btn btn-ghost btn-circle absolute right-2 top-2"
                >
                    <FontAwesomeIcon icon={faTimes} className="text-lg" />
                </button>
                <h2 className="text-xl font-bold mb-4">Top 100 Words</h2>
                <div>
                    {topWords.map((word, index) => {
                        const { width, color } = calculateWidthAndColor((index + 1).toString(), totalRanks);
                        return (
                            <div key={index} className="flex justify-between items-center p-2 border-2 border-secondary relative overflow-hidden">
                                <div
                                    className={`absolute top-0 left-0 bottom-0 z-0 ${color}`}
                                    style={{
                                        width: `${width}%`,
                                    }}
                                ></div>
                                <span className="z-10 relative">{index + 1}. {word}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};


const Proximity = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [word, setWord] = useState('');
    const [guesses, setGuesses] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [shareString, setShareString] = useState(``);
    const [hintsUsed, setHintsUsed] = useState(0);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [data, setData] = useState(null);
    const [answerRevealed, setAnswerRevealed] = useState(false);
    const [userId, setUserId] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get('date');
    const [currentDate, setCurrentDate] = useState(null);
    const nyDate = useNewYorkDate();
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [isTop100ModalOpen, setIsTop100ModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');
    const [dataLoadFailed, setDataLoadFailed] = useState(false);
    const [gameWon, setGameWon] = useState(false);

    const handleConfirmAction = (action) => {
        setConfirmAction(action);
        setIsConfirmModalOpen(true);
    };

    const executeConfirmedAction = () => {
        switch (confirmAction) {
            case 'reveal the answer':
                handleRevealAnswer();
                break;
            default:
                break;
        }
        setIsConfirmModalOpen(false);
    };

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/', { state: { date: currentDate } });
    };

    const resetGameState = useCallback((newDate) => {
        setIsModalOpen(false);
        setIsInfoModalOpen(false);
        setWord('');
        setGuesses([]);
        setSuggestions([]);
        setShowSuggestions(false);
        setErrorMessage('');
        setShareString(`Proximity ${newDate} `);
        setHintsUsed(0);
        setSelectedSuggestionIndex(-1);
        setData(null);
        setAnswerRevealed(false);
        setIsGameComplete(false);
        setIsTop100ModalOpen(false);
        setCurrentDate(newDate);
    }, []);

    const handleDateChange = useCallback((newDate) => {
        resetGameState(newDate);
        navigate(`/proximity?date=${newDate}`);
    }, [resetGameState, navigate]);

    useEffect(() => {
        let newDate;
        if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
            newDate = dateParam;
        } else {
            newDate = nyDate;
        }
        setCurrentDate(newDate);
    }, [dateParam, nyDate]);

    const checkGameCompletion = useCallback(() => {
        if (guesses.some(guess => guess.rank === '1') || answerRevealed) {
            setIsGameComplete(true);
        }
    }, [guesses, answerRevealed]);

    useEffect(() => {
        checkGameCompletion();
    }, [guesses, answerRevealed, checkGameCompletion]);

    const handleTop100Click = () => {
        setIsTop100ModalOpen(true);
    };

    useEffect(() => {
        const fetchUserId = async () => {
            const id = await getStoredUserId();
            setUserId(id);
        };
        fetchUserId();
    }, []);

    const sendGameData = useCallback(async (guess) => {
        if (!dateParam || !userId) return; 
        try {
            const source = ['ios', 'android'].includes(Capacitor.getPlatform()) ? Capacitor.getPlatform() : 'web';
            const payload = {
                date: dateParam.split('T')[0],
                game: "proximity",
                userid: userId,
                word: guess ? (guess.isHint ? `${guess.word}-Hint` : guess.word) : null,
                rank: guess ? guess.rank : null,
                revealed: guess ? (guess.isRevealed ? true : false) : false,
                source: source
            };    
            await axios.post('https://nu5koio7l8.execute-api.us-east-1.amazonaws.com/prod', payload);
        } catch (error) {
            console.error("Error triggering Lambda:", error);
        }
    }, [userId, dateParam]);

    const lemmatizeWord = (word) => {
        const lowercased = word.toLowerCase();
        const nounLemma = lemmatize.noun(lowercased);
        if (ranking.includes(nounLemma)) return nounLemma;
        const verbLemma = lemmatize.verb(lowercased);
        if (ranking.includes(verbLemma)) return verbLemma;
        const adjLemma = lemmatize.adjective(lowercased);
        if (ranking.includes(adjLemma)) return adjLemma;
        return word;
    };

    const handleRevealAnswer = () => {
        const topWord = ranking[0];
        addNewGuess({ word: topWord, rank: '1', isRevealed: true });
        setAnswerRevealed(true);
        setIsModalOpen(true);
        setShareString(`Proximity ${currentDate} 🤔 ${guesses.length + 1} Guesses 💡 ${hintsUsed} Hints ❗Answer Revealed ridella.xyz/proximity`);
        updatePlayHistory(currentDate, 'proximity', 'completed');
    };

    const fetchDataFromS3 = useCallback(async () => {
        const url = `https://proximity-game.s3.amazonaws.com/${currentDate}-proximity-ai.json`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData);
            const status = await getGameStatus(currentDate, 'proximity');
            if (!status) {
                updatePlayHistory(currentDate, 'proximity', 'inprogress');
            }
            const { value } = await Preferences.get({ key: 'proximityGameState' });
            if (value) {
                const parsedState = JSON.parse(value);
                if (parsedState.date === currentDate) {
                    setGuesses(parsedState.guesses);
                    setHintsUsed(parsedState.hintsUsed);
                    setAnswerRevealed(parsedState.answerRevealed);
                    if (parsedState.answerRevealed || parsedState.guesses.some(guess => guess.rank === '1')) {
                        setIsModalOpen(true);
                        setShareString(`Proximity ${currentDate} 🤔 ${parsedState.guesses.length} Guesses 💡 ${parsedState.hintsUsed} Hints ${parsedState.answerRevealed ? '❗Answer Revealed' : ''} ridella.xyz/proximity`);
                    }
                } else {
                    await Preferences.remove({ key: 'proximityGameState' });
                }
            }
            sendGameData(null);
        } catch (error) {
            setDataLoadFailed(true);
            console.error("Error fetching data from S3:", error);
        }
    }, [currentDate, setData, setGuesses, setHintsUsed, setAnswerRevealed, setIsModalOpen, setShareString, sendGameData]);

    useEffect(() => {
        if (currentDate) {
            fetchDataFromS3();
        }
    }, [fetchDataFromS3, currentDate]);

    useEffect(() => {
        if (currentDate && (guesses.length > 0 || hintsUsed > 0 || answerRevealed)) {
            const stateToSave = {
                date: currentDate,
                guesses,
                hintsUsed,
                answerRevealed,
                shareString
            };
            Preferences.set({
                key: 'proximityGameState',
                value: JSON.stringify(stateToSave)
            });
        }
    }, [currentDate, guesses, hintsUsed, answerRevealed, shareString]);

    const ranking = data?.rankings ?? [];
    const totalRanks = ranking.length;
    const sortedRanking = [...ranking].sort((a, b) => a.localeCompare(b));

    const handleKeyDown = (event) => {
        if (!showSuggestions) return;
    
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                setSelectedSuggestionIndex(prevIndex => 
                    prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
                break;
            case 'ArrowUp':
                event.preventDefault();
                setSelectedSuggestionIndex(prevIndex => 
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
                break;
            case 'Enter':
                event.preventDefault();
                if (selectedSuggestionIndex >= 0) {
                    handleSuggestionClick(suggestions[selectedSuggestionIndex]);
                } else {
                    handleSubmit();
                }
                break;
            default:
                break;
        }
    };

    const handleHint = () => {
        if (answerRevealed) return;
        const sortedGuesses = getSortedGuesses().filter(guess => guess.rank !== 'Word not found');
        if (sortedGuesses.length === 0) {
            const hintWord = ranking[499];
            addNewGuess({ word: hintWord, rank: `${ranking.indexOf(hintWord) + 1}`, isHint: true });
        } else {
            const closestGuess = sortedGuesses.reduce((closest, current) => {
                const closestRank = parseInt(closest.rank);
                const currentRank = parseInt(current.rank);
                return currentRank < closestRank ? current : closest;
            });
            const closestRank = parseInt(closestGuess.rank);
            let targetRank
            if (closestRank > 1000) {
                targetRank = 500;
            } else {
                targetRank = Math.floor((closestRank + 1) / 2);
            }
            
            let hintWord = ranking[targetRank - 1];
            if (targetRank === 1) {
                const guessedWords = new Set(guesses.map(guess => guess.word.toLowerCase()));
                for (let i = 1; i < ranking.length; i++) {
                    const currentWord = ranking[i].toLowerCase();
                    if (!guessedWords.has(currentWord)) {
                        hintWord = currentWord;
                        targetRank = i + 1;
                        break;
                    }
                }
            }
            addNewGuess({ word: hintWord, rank: `${targetRank}`, isHint: true });
        }
        setHintsUsed(prev => prev + 1);
    };

    const addNewGuess = async (newGuess) => {
        const updatedGuesses = [newGuess, ...guesses];
        setGuesses(updatedGuesses);
        sendGameData(newGuess)
        if (newGuess.rank === '1') {
            setIsModalOpen(true);
            setShareString(`Proximity ${currentDate} 🤔 ${guesses.length + 1} Guesses 💡 ${hintsUsed} Hints ridella.xyz/proximity`);
            updatePlayHistory(currentDate, 'proximity', 'completed');
            checkAndroidAndLogEvent("proximity")
            setGameWon(true)
        }
        const stateToSave = {
            date: currentDate,
            guesses: updatedGuesses,
            hintsUsed,
            answerRevealed
        };
        await Preferences.set({
            key: 'proximityGameState',
            value: JSON.stringify(stateToSave)
        });
    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        setWord(input);
    
        if (input.length >= 2) {
            const filteredSuggestions = sortedRanking.filter(w =>
                w.toLowerCase().startsWith(input.toLowerCase())
            ).slice(0, 10); // Limit to 10 suggestions
            setSuggestions(filteredSuggestions);
            setShowSuggestions(true);
            setSelectedSuggestionIndex(-1); // Reset selected index
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
            setSelectedSuggestionIndex(-1); // Reset selected index
        }
    };

    const handleSubmit = () => {
        if (answerRevealed) return;
        const wordToSubmit = selectedSuggestionIndex >= 0 ? suggestions[selectedSuggestionIndex] : word.trim();
    
        if (!wordToSubmit) {
            setErrorMessage("Please enter a word before submitting.");
            setWord('');
            return;
        }    

    
        const lemmatizedWord = lemmatizeWord(wordToSubmit);
        const position = ranking.indexOf(lemmatizedWord);

        if (guesses.some(guess => guess.word.toLowerCase() === lemmatizedWord.toLowerCase())) {
            setErrorMessage(`${lemmatizedWord.toLowerCase()} already guessed`);
            setWord('');
            setSuggestions([]);
            setShowSuggestions(false); 
            return;
        }
        
        if (position === -1) {
            setErrorMessage(`${wordToSubmit} was not recognized`);
            setWord('');
            setSuggestions([]); 
            setShowSuggestions(false);    
            return;
        }
    
        const rank = `${position + 1}`;
        const newGuess = { word: lemmatizedWord, rank: rank };
        addNewGuess(newGuess);
        setWord('');
        setSuggestions([]);
        setShowSuggestions(false);
        setErrorMessage('');
        setSelectedSuggestionIndex(-1);
    };

    const handleSuggestionClick = (suggestion) => {
        if (guesses.some(guess => guess.word.toLowerCase() === suggestion.toLowerCase())) {
            setErrorMessage(`${suggestion.toLowerCase()} already guessed`);
            setWord('');
            setShowSuggestions(false);
            return;
        }

        const position = ranking.indexOf(suggestion);
        let rank = 'Word not found';
        if (position !== -1) {
            rank = `${position + 1}`;
        }
        const newGuess = { word: suggestion, rank: rank };
        addNewGuess(newGuess);
        setWord('');
        setShowSuggestions(false);
        setErrorMessage('');
    };

    const getColor = (widthPercentage) => {
        if (widthPercentage <= 20) return 'bg-[#ee6055] dark:bg-[#5D0F09]';
        if (widthPercentage <= 40) return 'bg-[#ff9b85] dark:bg-[#8F1A00]';
        if (widthPercentage <= 60) return 'bg-[#ffd97d] dark:bg-[#7A5600]';
        if (widthPercentage <= 80) return 'bg-[#aaf683] dark:bg-[#245F07]';
        return 'bg-[#60d394] dark:bg-[#165030]';
    };

    const calculateWidthAndColor = (rank, totalRanks) => {
        if (rank === 'Word not found') return { width: 0, color: '#ee6055' };

        const rankNum = parseInt(rank);
        const percentile = (totalRanks - rankNum + 1) / totalRanks;

        // Exponential function
        const width = Math.pow(percentile, 5) * 100;
        const clampedWidth = Math.max(0, Math.min(100, width));

        return {
            width: clampedWidth,
            color: getColor(clampedWidth)
        };
    };

    const getSortedGuesses = () => {
        if (guesses.length <= 1) return guesses;

        const [mostRecent, ...rest] = guesses;
        const sortedRest = rest.sort((a, b) => {
            if (a.rank === 'Word not found') return 1;
            if (b.rank === 'Word not found') return -1;
            return parseInt(a.rank) - parseInt(b.rank);
        });

        return [mostRecent, ...sortedRest];
    };

    return (
        <div className="rajdhani-font">
            <div className="pt-2 shadow-b mb-2 flex justify-between items-center rajdhani-font">
                <button onClick={handleBackClick} className="btn btn-ghost">
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </button>
                <div className="flex items-center justify-center flex-grow">
                    <img src={proximityImage} alt="Proximity" className="h-8 mr-2 dark:invert" />
                    <span className="font-bold text-xl">Proximity</span>
                </div>
                <button onClick={() => setIsInfoModalOpen(true)} className="ml-auto mr-2">
                    <FontAwesomeIcon icon={faCircleInfo} className="text-3xl" />
                </button>
            </div>
            <div className="flex items-center justify-between mb-4 mx-10">
                {isGameComplete ? (
                    <div className="w-full flex justify-center">
                        <button onClick={handleTop100Click} className="btn btn-primary text-white">
                            Top 100 Words
                        </button>
                    </div>
                ) : (
                    <>
                        <button onClick={() => handleConfirmAction('reveal the answer')} className="btn" disabled={answerRevealed}>
                            Reveal
                        </button>
                        <button onClick={handleHint} className="btn btn-secondary" disabled={answerRevealed}>
                            Hint
                        </button>
                    </>
                )}
            </div>
            {data ? (
            <div>
                <div className="mx-10">
                    <div className="form-control">
                        <div className="input-group relative">
                            <input
                                type="text"
                                placeholder="Type word here"
                                className={`input input-bordered w-full border-secondary border-4 focus:outline-none ${answerRevealed ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                                value={word}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                disabled={isGameComplete}
                            />
                            {!isGameComplete && (<button className="btn btn-primary absolute right-0 text-white rounded-l-none rounded-r-md" onClick={handleSubmit}>Submit</button>)}
                            {showSuggestions && suggestions.length > 0 && (
                                <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-b-md shadow-lg z-50 dark:text-black">
                                    {suggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className={`p-2 hover:bg-gray-100 cursor-pointer ${
                                                index === selectedSuggestionIndex ? 'bg-gray-200' : ''
                                            }`}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    {errorMessage && <p className="mt-2">{errorMessage}</p>}
                    <div className="mt-4 mb-8 border-2 border-secondary rounded-lg overflow-hidden">
                        {guesses.length === 0 ? (
                            <div className="p-4">
                                <h3 className="text-xl font-bold mb-2">How To Play</h3>
                                <ul className="list-disc list-inside">
                                    <li>Guess words related to today's theme.</li>
                                    <li>Each guess is ranked based on how close it is to the target word.</li>
                                    <li>The closer your guess, the fuller and greener the bar.</li>
                                    <li>Try to find the #1 ranked word!</li>
                                </ul>
                            </div>
                        ) : (
                            getSortedGuesses().map((guess, index) => {
                                const { width, color } = calculateWidthAndColor(guess.rank, totalRanks);
                                return (
                                    <div key={index} className="flex justify-between items-center p-2 border-secondary relative border-2">
                                        <div
                                            className={`absolute top-0 left-0 bottom-0 z-0 ${color}`}
                                            style={{
                                                width: `${width}%`,
                                            }}
                                        ></div>
                                        <span className={`font-medium z-1 relative`}>
                                            {guess.isHint && '💡'}{guess.word}
                                        </span>
                                        <span className="text-right z-1 relative">{guess.rank}</span>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            ) : !dataLoadFailed ? (
                <div className="flex flex-col items-center justify-center">
                <h2 className="text-2xl font-bold mb-4">Loading</h2>
                <span className="loading loading-dots loading-lg"></span>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center text-center">
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">There was an error loading the game.</h2>
                    <FontAwesomeIcon icon={faFaceDisappointed} className="text-4xl mt-4" />
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">Please try again later.</h2>
                </div>
            )}
            <CompletedModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} game="proximity" shareText={shareString} currentDate={currentDate} onDateChange={handleDateChange}/>
            <InfoModal isOpen={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)} />
            <Top100Modal isOpen={isTop100ModalOpen} onClose={() => setIsTop100ModalOpen(false)} topWords={ranking.slice(0, 100)} calculateWidthAndColor={calculateWidthAndColor} totalRanks={ranking.length} />
            {gameWon && <RatingAlert/>}
            {isConfirmModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 dark:text-black">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Confirmation</h2>
                        <p className="mb-4">Are you sure you want to {confirmAction}?</p>
                        <div className="flex justify-end">
                            <button 
                                className="btn btn-ghost mr-5"
                                onClick={() => setIsConfirmModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button 
                                className="btn btn-primary mr-2 text-white"
                                onClick={executeConfirmedAction}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Proximity;
