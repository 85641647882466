// Clique.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleInfo, faCircle, faDeleteLeft, faFaceDisappointed } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import CompletedModal from './CompletedModal';
import { getStoredUserId } from './Userid';
import { updatePlayHistory } from './playHistoryUtil';
import cliqueImage from '../assets/clique.png';
import InfoModal from './InfoModal';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { fetchCliqueData } from './FetchGameData';
import { useNewYorkDate } from './DateTime';
import { checkAndroidAndLogEvent } from './FirebaseEvent';
import RatingAlert from './RatingAlert';

const AnimatedOval = ({ word, isAnimating, originY }) => {
    return (
      <div
        className={`fixed left-1/2 ${originY} -translate-x-1/2 bg-gray-400 text-white font-bold rounded-full px-4 py-2 ${
          isAnimating ? 'animate-move-shrink' : 'hidden'
        }`}
        style={{
          transform: isAnimating ? 'translateX(-50%)' : 'translateX(-50%) translateY(-50%)',
        }}
      >
        {word}
      </div>
    );
  };

const Clique = () => {
    const [puzzle, setPuzzle] = useState(null);
    const [basePuzzle, setBasePuzzle] = useState(null);
    const [letters, setLetters] = useState(null);
    const [highlightedCells, setHighlightedCells] = useState(new Set());
    const [activeLetter, setActiveLetter] = useState({ row: null, col: null });
    const [completedWords, setCompletedWords] = useState(new Set());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);    
    const [wordList, setWordList] = useState(new Set());
    const [foundWords, setFoundWords] = useState(new Set());
    const [numHints, setNumHints] = useState(0);
    const [hintRowIndex, setHintRowIndex] = useState(null);
    const [data, setData] = useState({});
    const [startTime, setStartTime] = useState(null);
    const [foundClique, setFoundClique] = useState(null);
    const [shakingRow, setShakingRow] = useState(null);
    const [droppingLetter, setDroppingLetter] = useState(null);
    const [animatingWord, setAnimatingWord] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);
    const [isGameCompleted, setIsGameCompleted] = useState(false);
    const [leftSideContent, setLeftSideContent] = useState('');
    const [shareString, setShareString] = useState('');
    const [userId, setUserId] = useState(null);
    const initialSetupDone = useRef(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get('date');
    const [currentDate, setCurrentDate] = useState(null);
    const nyDate = useNewYorkDate();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isHintActive, setIsHintActive] = useState(false);
    const [dataLoadFailed, setDataLoadFailed] = useState(false);
    const [gameWon, setGameWon] = useState(false);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/', { state: { date: currentDate } });
    };

    const resetGameState = useCallback((newDate) => {
        setPuzzle(null);
        setBasePuzzle(null);
        setLetters(null);
        setHighlightedCells(new Set());
        setActiveLetter({ row: null, col: null });
        setCompletedWords(new Set());
        setFoundWords(new Set());
        setNumHints(0);
        setHintRowIndex(null);
        setData({});
        setStartTime(null);
        setFoundClique(null);
        setShareString('');
        setIsGameCompleted(false);
        setCurrentDate(newDate);
        setLeftSideContent('')
        initialSetupDone.current = false;
        initialFetchDone.current = false;
    }, []);

    const handleDateChange = useCallback((newDate) => {
        resetGameState(newDate);
        navigate(`/clique?date=${newDate}`);
    }, [resetGameState, navigate]);

    useEffect(() => {
        let newDate;
        if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
            newDate = dateParam;
        } else {
            newDate = nyDate;
        }
        setCurrentDate(newDate);
    }, [dateParam, nyDate]);

    useEffect(() => {
        let timer;
        if (leftSideContent && leftSideContent.props.children === "WORD NOT RECOGNIZED") {
            timer = setTimeout(() => {
                setLeftSideContent('');
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [leftSideContent]);

    const findFirstEmptyCell = useCallback((puzzle) => {
        for (let row = 0; row < puzzle.length; row++) {
            for (let col = 0; col < puzzle[row].length; col++) {
                if (puzzle[row][col] === null) {
                    return { row, col };
                }
            }
        }
        return { row: null, col: null };
    }, []);

    useEffect(() => {
        const fetchUserId = async () => {
            const id = await getStoredUserId();
            console.log("userid", id);
            setUserId(id);
        };
        fetchUserId();
    }, []);

    const sendGameData = useCallback(async (solved, guess, wasCorrect, timeTaken) => {
        if (!dataLoaded || !data.date) return; 
        try {
            const source = ['ios', 'android'].includes(Capacitor.getPlatform()) ? Capacitor.getPlatform() : 'web';
            const payload = {
                date: data.date,
                game: "clique",
                userid: userId,
                solved: solved,
                guess: guess,
                wasCorrect: wasCorrect,
                timeTaken: timeTaken,
                source: source
            };    
            await axios.post('https://nu5koio7l8.execute-api.us-east-1.amazonaws.com/prod', payload);
        } catch (error) {
            console.error("Error triggering Lambda:", error);
        }
    }, [userId, data.date, dataLoaded]);

    const saveGameState = useCallback(async () => {
        if (data.date) {
            const gameState = {
                puzzle,
                letters,
                completedWords: Array.from(completedWords),
                foundWords: Array.from(foundWords),
                numHints,
                startTime,
                foundClique,
                answers: data.answers,
                theme: data.theme,
                shareString
            };
            try {
                const { value } = await Preferences.get({ key: 'cliqueGameStates' });
                const allGameStates = value ? JSON.parse(value) : {};
                allGameStates[data.date] = gameState;
                await Preferences.set({
                    key: 'cliqueGameStates',
                    value: JSON.stringify(allGameStates)
                });
            } catch (error) {
                console.error('Error saving game state:', error);
            }
        }
    }, [data, puzzle, letters, completedWords, foundWords, numHints, startTime, foundClique, shareString]);

    useEffect(() => {
        saveGameState();
    }, [saveGameState]);

    const extractHighlightedCells = useCallback(() => {
        if (!data.category || !data.answers || highlightedCells.size > 0) return;
        const newHighlightedCells = new Set();
        for (let charIndex = 0; charIndex < data.category.length; charIndex++) {
            const char = data.category[charIndex];
            for (let col = 0; col < data.answers[charIndex].length; col++) {
                if (data.answers[charIndex][col] === char) {
                    newHighlightedCells.add(JSON.stringify({ row: charIndex, col }));
                    break;
                }
            }
        }
        setHighlightedCells(newHighlightedCells);
    }, [data.category, data.answers, highlightedCells]);

    const extractPuzzle = useCallback(() => {
        if (!data.puzzle || puzzle) return;
        setPuzzle(data.puzzle)
    }, [data.puzzle, puzzle]);

    const extractLetters = useCallback(() => {
        if (!data.puzzle || letters) return;
        const letterBank = [];
        let index = 0;
        for (let i = 0; i < data.puzzle.length; i++) {
            for (let j = 0; j < data.puzzle[i].length; j++) {
                if (!data.puzzle[i][j]) {
                    letterBank.push({
                        index: index,
                        letter: data.answers[i][j],
                        used: false,
                        highlighted: false
                    });
                    index++;
                }
            }
        }
        const sortedLetters = letterBank.sort((a, b) => a.letter.localeCompare(b.letter));
        setLetters(sortedLetters);
    }, [data.puzzle, data.answers, letters]);

    const setGameState = useCallback((newData, savedData = null) => {
        console.log(newData)
        setBasePuzzle(newData.puzzle);
        if (savedData) {
            setData({
                ...newData,
                puzzle: savedData.puzzle,
                answers: savedData.answers,
                theme: savedData.theme,
            });
            setPuzzle(savedData.puzzle);
            setLetters(savedData.letters);
            setCompletedWords(new Set(savedData.completedWords));
            setFoundWords(new Set(savedData.foundWords));
            setNumHints(savedData.numHints);
            setStartTime(savedData.startTime);
            setFoundClique(savedData.foundClique);
            setShareString(savedData.shareString);
        } else {
            setData(newData);
            setStartTime(Date.now());
            setShareString(`Clique ${newData.date} "${newData.theme}" `);
            updatePlayHistory(currentDate, 'clique', 'inprogress');
        }
        setDataLoaded(true);
    }, [currentDate]);

    const fetchData = useCallback(async () => {
        try {
            const { newData, savedData } = await fetchCliqueData(currentDate);
            setGameState(newData, savedData);
        } catch (error) {
            setDataLoadFailed(true)
            console.error('There was an error!', error);
        }
    }, [currentDate, setGameState]);

    const initialFetchDone = useRef(false);

    useEffect(() => {
        const fetchDataAndStartGame = async () => {
            if (currentDate && !initialFetchDone.current) {
                await fetchData();
                initialFetchDone.current = true;
            }
        };
        fetchDataAndStartGame();
    }, [fetchData, currentDate]);

    useEffect(() => {
        if (dataLoaded) {
            sendGameData(false);
        }
    }, [dataLoaded, sendGameData]);
    
    useEffect(() => {
        if (data.puzzle && !initialSetupDone.current) {
            extractLetters();
            extractPuzzle();
            extractHighlightedCells();
            const firstEmptyCell = findFirstEmptyCell(data.puzzle);
            setActiveLetter(firstEmptyCell);
            initialSetupDone.current = true;
        }
    }, [data, extractLetters, extractPuzzle, extractHighlightedCells, findFirstEmptyCell]);

    useEffect(() => {
        fetch('wordlist.txt')
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                setWordList(new Set(lines));
            });
    }, []);

    const CompletedLetter = ({ letter, onClick, isActive, isUserEntered, isHighlighted, rowIndex, letterIndex, rowLength, style, isShaking, isDropping, isCliqueLetter, foundClique }) => {
        const isCompleted = completedWords.has(rowIndex);
        const borderRadius = getBorderRadius(rowIndex, letterIndex, rowLength);
        const handleClick = (e) => {
            if (!isCompleted && !(isCliqueLetter && foundClique)) {
                onClick(e, rowIndex, letterIndex);
            }
        };
        return (
            <div
                className={`w-12 h-12 flex items-center justify-center text-xl font-bold
                    ${isActive ? 'border-2 border-primary' : ''}
                    ${letter === null ? 'bg-gray-300' : 'bg-white'}
                    ${isUserEntered && !isCompleted ? 'text-blue-500' : ''} 
                    ${isUserEntered && isCompleted ? 'dark:text-black' : ''} 
                    ${!isUserEntered ? 'dark:text-black' : ''} 
                    ${isHighlighted ? '!bg-secondary' : ''} 
                    ${isCliqueLetter && foundClique ? '!text-primary' : ''}
                `}
                onClick={handleClick}
                style={{
                    borderRadius,
                    width: '2.75rem',
                    height: '2.75rem',
                    ...style,
                    ...(isShaking ? {
                        animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
                    } : {}),
                    ...(isDropping ? {
                        animation: 'dropDown 0.3s ease-in forwards',
                    } : {}),
                    cursor: (isCliqueLetter && foundClique) ? 'default' : 'pointer'
                }}
            >
                {letter === null ? (
                    <div className="bg-white rounded-full flex items-center justify-center w-4 h-4" >
                        <FontAwesomeIcon icon={faCircle} style={{ color: 'transparent' }} />
                    </div>
                ) : (
                    letter
                )}
            </div>
        );
    };

    const getBorderRadius = (rowIndex, letterIndex, rowLength) => {
        let borderRadius = '0.375rem';
        if (letterIndex === 0) {
            borderRadius = '0.375rem 0 0 0.375rem';
        } else if (letterIndex === rowLength - 1) {
            borderRadius = '0 0.375rem 0.375rem 0';
        } else {
            borderRadius = '0';
        }
        return borderRadius;
    };

    const removeActiveLetter = useCallback(() => {
        if (activeLetter.row === null || activeLetter.col === null) return;
    
        const { row, col } = activeLetter;
        const currentLetter = puzzle[row][col];
    
        const removeLetterFromCell = (targetRow, targetCol, letterToRemove) => {
            if (foundClique && highlightedCells.has(JSON.stringify({ row: targetRow, col: targetCol }))) {
                return;
            }
            setDroppingLetter({ row: targetRow, col: targetCol });
            setTimeout(() => {
                const updatedPuzzle = [...puzzle];
                updatedPuzzle[targetRow][targetCol] = null;
    
                const updatedLetters = [...letters];
                const indexToUpdate = updatedLetters.findIndex(letter => 
                    letter.letter === letterToRemove && letter.used
                );
                if (indexToUpdate !== -1) {
                    updatedLetters[indexToUpdate] = { ...updatedLetters[indexToUpdate], used: false };
                }
    
                setPuzzle(updatedPuzzle);
                setLetters(updatedLetters);
                setDroppingLetter(null);
                setReturnedLetterIndex(indexToUpdate);
            }, 300);
        };
    
        if (currentLetter !== null && basePuzzle[row][col] === null) {
            removeLetterFromCell(row, col, currentLetter);
        } else {
            for (let newCol = col - 1; newCol >= 0; newCol--) {
                if (basePuzzle[row][newCol] === null) {
                    if (puzzle[row][newCol] !== null) {
                        removeLetterFromCell(row, newCol, puzzle[row][newCol]);
                    }
                    if (!foundClique || !highlightedCells.has(JSON.stringify({ row, col: newCol }))) {
                        setActiveLetter({ row, col: newCol });
                    }
                    break;
                }
            }
        }
    }, [activeLetter, puzzle, letters, basePuzzle, foundClique, highlightedCells]);

    const handleLetterClick = (row, col) => {
        const isCliqueLetter = highlightedCells.has(JSON.stringify({ row, col }));
        if (basePuzzle[row][col] === null && !(isCliqueLetter && foundClique)) {
            setActiveLetter({ row, col });    
        }
    };

    const returnLetters = useCallback((updatedPuzzle, rowIndex, row, updatedLetters) => {
        const justPlacedLetters = row.map((letter, index) => ({
            letter,
            index,
            shouldReturn: letter !== null && 
                          basePuzzle[rowIndex][index] === null && 
                          !(foundClique && highlightedCells.has(JSON.stringify({ row: rowIndex, col: index })))
        })).filter(item => item.shouldReturn);
        const newLetters = [...updatedLetters];
        justPlacedLetters.forEach(({ letter: letterToReturn }) => {
            const indexToReturn = newLetters.findIndex(letterObj => 
                letterObj.letter === letterToReturn && letterObj.used === true
            );
            if (indexToReturn !== -1) {
                newLetters[indexToReturn] = { ...newLetters[indexToReturn], used: false };
                console.log(`Returning letter: ${letterToReturn}`);
            }
        });
        const newPuzzle = updatedPuzzle.map((puzzleRow, i) => {
            if (i === rowIndex) {
                return puzzleRow.map((cell, index) => {
                    if (foundClique && highlightedCells.has(JSON.stringify({ row: rowIndex, col: index }))) {
                        return cell;
                    }
                    if (basePuzzle[rowIndex][index] === null) {
                        return null;
                    }
                    return cell;
                });
            }
            return puzzleRow;
        });
        setPuzzle(newPuzzle);
        setLetters(newLetters);
    }, [basePuzzle, foundClique, highlightedCells, setPuzzle, setLetters]);

    useEffect(() => {
        if (data.answers && completedWords.size === data.answers.length) {
            setIsModalOpen(true);
            setIsGameCompleted(true);
            setGameWon(true);
            updatePlayHistory(currentDate, 'clique', 'completed');
            checkAndroidAndLogEvent("clique")
        }
    }, [completedWords, data, currentDate]);   

    const checkClique = useCallback((updatedPuzzle) => {
        let allMatched = true;
        highlightedCells.forEach(cell => {
            const {row, col} = JSON.parse(cell);
            const puzzleChar = updatedPuzzle[row][col];
            const answerChar = data.answers[row][col];
            if (puzzleChar !== answerChar) {
                allMatched = false;
            }
        });
        if (allMatched) {
            setFoundClique(true);
            setLeftSideContent(<span className="text-primary text-3xl rajdhani-font font-bold">CLIQUED!</span>);
            setShareString(prevShareString => prevShareString + '🟣');
        }
    }, [highlightedCells, data.answers, setFoundClique, setLeftSideContent, setShareString]);

    const returnAllLetters = useCallback(() => {
        const updatedPuzzle = puzzle.map((row, rowIndex) => {
            if (completedWords.has(rowIndex)) {
                return row;
            }
            return row.map((cell, colIndex) => {
                if (foundClique && highlightedCells.has(JSON.stringify({ row: rowIndex, col: colIndex }))) {
                    return cell;
                }
                if (basePuzzle[rowIndex][colIndex] !== null) {
                    return basePuzzle[rowIndex][colIndex];
                }
                return null;
            });
        });

        // Create a map to track which letters from the bank are actually used
        const usedLettersMap = new Map();
        updatedPuzzle.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
                if (cell !== null && basePuzzle[rowIndex][colIndex] === null) {
                    const key = `${cell}-${usedLettersMap.get(cell) || 0}`;
                    usedLettersMap.set(key, (usedLettersMap.get(key) || 0) + 1);
                }
            });
        });

        const updatedLetters = letters.map(letterObj => {
            const key = `${letterObj.letter}-${usedLettersMap.get(letterObj.letter) || 0}`;
            if (usedLettersMap.get(key) > 0) {
                usedLettersMap.set(key, usedLettersMap.get(key) - 1);
                return { ...letterObj, used: true };
            }
            return { ...letterObj, used: false };
        });

        setPuzzle(updatedPuzzle);
        setLetters(updatedLetters);
    }, [puzzle, completedWords, foundClique, highlightedCells, basePuzzle, letters]);

    const handleHintClick = async () => {
        if (isHintActive) return;
        returnAllLetters();
        const firstUnansweredIndex = puzzle.findIndex((row, index) => !completedWords.has(index));
        setHintRowIndex(firstUnansweredIndex);  // Set this row index to be highlighted as hint
        setIsHintActive(true);
        const answer = data.answers[firstUnansweredIndex];
        const puzzleRow = puzzle[firstUnansweredIndex];
        const missingLetters = answer.split('').filter((ansLetter, index) => {
            return puzzleRow[index] === null || puzzleRow[index].toUpperCase() !== ansLetter.toUpperCase();
        });
        setLetters(currentLetters => {
            let updatedLetters = [...currentLetters];
            missingLetters.forEach(missingLetter => {
                const foundIndex = updatedLetters.findIndex(letterObj => 
                    !letterObj.used && letterObj.letter.toUpperCase() === missingLetter && !letterObj.highlighted
                );
                if (foundIndex !== -1) {
                    updatedLetters[foundIndex] = { ...updatedLetters[foundIndex], highlighted: true };
                }
            });
            return updatedLetters;
        });
        setNumHints(prevNumHints => prevNumHints + 1);
        setShareString(prevShareString => prevShareString + '💡');
        const timeTaken = (Date.now() - startTime) / 1000;
        sendGameData(false, "HINT USED", false, timeTaken);
    }

    const [returnedLetterIndex, setReturnedLetterIndex] = useState(null);
    const [pulsingRow, setPulsingRow] = useState(null);
    const [pulsingLetter, setPulsingLetter] = useState(null);
    const cliqueAnimationRan = useRef(false);
    
    useEffect(() => {
      if (returnedLetterIndex !== null) {
        const timeout = setTimeout(() => setReturnedLetterIndex(null), 1000);
        return () => clearTimeout(timeout);
      }
    }, [returnedLetterIndex]);

    useEffect(() => {
        if (foundClique && !cliqueAnimationRan.current) {
            cliqueAnimationRan.current = true;
            const indices = [];
            puzzle.forEach((row, rowIndex) => {
                row.forEach((letter, letterIndex) => {
                    if (highlightedCells.has(JSON.stringify({ row: rowIndex, col: letterIndex }))) {
                        indices.push({ rowIndex, letterIndex });
                    }
                });
            });
            indices.sort((a, b) => a.rowIndex - b.rowIndex || a.letterIndex - b.letterIndex);    
            let timeoutIds = [];
            indices.forEach((index, i) => {
                const timeoutId = setTimeout(() => {
                    setPulsingLetter(index);
                    const clearTimeoutId = setTimeout(() => {
                        setPulsingLetter(null);
                    }, 500);
                    timeoutIds.push(clearTimeoutId);
                }, i * 600);
                timeoutIds.push(timeoutId);
            });
            const resetTimeoutId = setTimeout(() => {
                setPulsingLetter(null);
            }, indices.length * 600 + 500);
            timeoutIds.push(resetTimeoutId);
            return () => {
                timeoutIds.forEach(clearTimeout);
            };
        }
    }, [foundClique, puzzle, highlightedCells]);      
        
    const letterPulseStyles = {
      animationName: 'letterPulse',
      animationDuration: '0.5s',
      animationIterationCount: '1'
    };

    const rowPulseStyles = {
        animationName: 'rowPulse',
        animationDuration: '0.5s',
        animationIterationCount: '1'
      };

      useEffect(() => {
        const keyframes = [
            `@keyframes letterPulse {
                0% { transform: scale(1); }
                50% { transform: scale(1.2); }
                100% { transform: scale(1); }
            }`,
            `@keyframes rowPulse {
                0% { transform: scale(1); }
                50% { transform: scale(1.1); }
                100% { transform: scale(1); }
            }`,
            `@keyframes shake {
                0%, 100% { transform: translateX(0); }
                20%, 60% { transform: translateX(-5px); }
                40%, 80% { transform: translateX(5px); }
            }`,
            `@keyframes dropDown {
                0% { transform: translateY(0); opacity: 1; }
                100% { transform: translateY(20px); opacity: 0; }
            }`,
            `@keyframes growProgressBar {
                from { background-size: 0% 100%; }
                to { background-size: 100% 100%; }
            }`,
            `@keyframes moveAndShrink {
                0% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
                100% { transform: translate(calc(-50% + 150px), calc(-50% - 150px)) scale(0.2); opacity: 0; }
              }`
        ];
    
        if (typeof document !== 'undefined') {
            const styleSheet = document.styleSheets[0];
            keyframes.forEach(rule => {
                try {
                    styleSheet.insertRule(rule, styleSheet.cssRules.length);
                } catch (e) {
                    console.error('Error inserting rule:', e);
                }
            });
        }
    }, []);

    const checkAnswer = useCallback(async (updatedPuzzle, rowIndex, updatedLetters) => {
        const row = updatedPuzzle[rowIndex];
        const word = row.filter(letter => letter !== null).join('')
        let wasCorrect = false
        let isFinalAnswer = false
        if (data.answers[rowIndex] === word && !completedWords.has(rowIndex) && word.length === updatedPuzzle[rowIndex].length) {
            wasCorrect = true
            const newCompletedWords = new Set([...completedWords, rowIndex]);
            setCompletedWords(newCompletedWords);
            isFinalAnswer = newCompletedWords.size === data.answers.length;
            setActiveLetter({ row: null, col: null });
            setPulsingRow(rowIndex);
            setLeftSideContent(<span className="text-primary text-3xl rajdhani-font font-bold">{word.toUpperCase()}</span>);
            setShareString(prevShareString => prevShareString + '🔵');
            if (rowIndex === hintRowIndex) {
                setHintRowIndex(null);
                setIsHintActive(false); 
                setLetters(currentLetters => currentLetters.map(letter => ({
                    ...letter,
                    highlighted: false
                })));
            }
        } else if (wordList.has(word.toLowerCase()) && !foundWords.has(word) && word.length === updatedPuzzle[rowIndex].length) {
            setFoundWords(new Set([...foundWords, word]));
            setAnimatingWord(word);
            setIsAnimating(true);
            setLeftSideContent(<span className="text-gray-500 text-3xl rajdhani-font font-bold">{word.toUpperCase()}</span>);
            setTimeout(() => {
              setIsAnimating(false);
              setAnimatingWord('');
            }, 1000); 
            returnLetters(updatedPuzzle, rowIndex, row, updatedLetters);
        } else if (wordList.has(word.toLowerCase()) && foundWords.has(word) && word.length === updatedPuzzle[rowIndex].length) {
            setLeftSideContent(<span className="text-gray-500 text-xl rajdhani-font font-bold">WORD ALREADY FOUND</span>);
            returnLetters(updatedPuzzle, rowIndex, row, updatedLetters);
        } else if (word.length === updatedPuzzle[rowIndex].length) {
            setShakingRow(rowIndex);
            setLeftSideContent(<span className="text-gray-500 text-xl rajdhani-font font-bold">WORD NOT RECOGNIZED</span>);
            setTimeout(() => {
                setShakingRow(null);
                returnLetters(updatedPuzzle, rowIndex, row, updatedLetters);
            }, 820); 
        }
        if (!shareString.includes('🟣')) {
            checkClique(updatedPuzzle);
        }
        if (word.length === updatedPuzzle[rowIndex].length) {
            const timeTaken = (Date.now() - startTime) / 1000;
            sendGameData(isFinalAnswer, word, wasCorrect, timeTaken);
        }
    }, [
        data.answers,
        completedWords,
        hintRowIndex,
        wordList,
        foundWords,
        shareString,
        startTime,
        // data.date,
        setCompletedWords,
        setActiveLetter,
        setPulsingRow,
        setLeftSideContent,
        setShareString,
        setHintRowIndex,
        setLetters,
        setFoundWords,
        setAnimatingWord,
        setIsAnimating,
        setShakingRow,
        returnLetters,
        checkClique,
        sendGameData
    ]);

    const handleTileClick = useCallback((letterObj, index) => {
        if (activeLetter.row !== null && activeLetter.col !== null) {
            const currentLetter = puzzle[activeLetter.row][activeLetter.col];
    
            const updatedPuzzle = puzzle.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    if (rowIndex === activeLetter.row && colIndex === activeLetter.col) {
                        return letterObj.letter;
                    }
                    return cell;
                });
            });
        
            const updatedLetters = letters.map((currentLetter, letterIndex) => {
                if (letterIndex === index) {
                    return { ...currentLetter, used: true };
                }
                return currentLetter;
            });
        
            if (currentLetter !== null) {
                const oldLetterIndex = updatedLetters.findIndex(l => l.letter === currentLetter && l.used);
                if (oldLetterIndex !== -1) {
                    updatedLetters[oldLetterIndex] = { ...updatedLetters[oldLetterIndex], used: false };
                }
            }
        
            setPuzzle(updatedPuzzle);
            setLetters(updatedLetters);
            checkAnswer(updatedPuzzle, activeLetter.row, updatedLetters);
    
            let newRow = activeLetter.row;
            let newCol = activeLetter.col + 1;
            let found = false;
            while (newCol < updatedPuzzle[newRow].length) {
                if (updatedPuzzle[newRow][newCol] === null) {
                    setActiveLetter({ row: newRow, col: newCol });
                    found = true;
                    break;
                }
                newCol++;
            }
            if (!found) {
                for (let row = 0; row < updatedPuzzle.length; row++) {
                    for (let col = 0; col < updatedPuzzle[row].length; col++) {
                        if (updatedPuzzle[row][col] === null) {
                            setActiveLetter({ row: row, col: col });
                            found = true;
                            break;
                        }
                    }
                    if (found) break;
                }
            }
        }
    }, [activeLetter, puzzle, letters, checkAnswer, setActiveLetter]);

    const handleKeyDown = useCallback((event) => {
        const key = event.key.toUpperCase();
        if (key === 'BACKSPACE' || key === 'DELETE') {
            removeActiveLetter();
        } else if (key === 'ARROWLEFT' || key === 'ARROWRIGHT' || key === 'ARROWUP' || key === 'ARROWDOWN') {
            let newRow = activeLetter.row;
            let newCol = activeLetter.col;
            if (newRow !== null && newCol !== null) {
                if (key === 'ARROWRIGHT' || key === 'ARROWLEFT') {
                    const increment = key === 'ARROWRIGHT' ? 1 : -1;
                    for (let col = newCol + increment; col >= 0 && col < puzzle[newRow].length; col += increment) {
                        if (basePuzzle[newRow][col] === null) {
                            newCol = col;
                            break;
                        }
                    }
                } else if (key === 'ARROWUP' || key === 'ARROWDOWN') {
                    const increment = key === 'ARROWDOWN' ? 1 : -1;
                    for (let row = newRow + increment; row >= 0 && row < puzzle.length; row += increment) {
                        let found = false;
                        for (let col = 0; col < puzzle[row].length; col++) {
                            if (puzzle[row][col] === null) {
                                newRow = row;
                                newCol = col;
                                found = true;
                                break;
                            }
                        }
                        if (found) break;
                    }
                } 
            }
            if (!foundClique || !highlightedCells.has(JSON.stringify({ row: newRow, col: newCol }))) {
                setActiveLetter({ row: newRow, col: newCol });
            }
        } else if (key.length === 1 && /[A-Z]/.test(key)) {
            // Loop through letters to find a match
            for (let i = 0; i < letters.length; i++) {
                const letterObj = letters[i];
                if (!letterObj.used && letterObj.letter.toUpperCase() === key) {
                    handleTileClick(letterObj, i);
                    break;  // Exit the loop once we've found and used a matching letter
                }
            }
        }
    }, [removeActiveLetter, activeLetter, puzzle, letters, handleTileClick, basePuzzle, foundClique, highlightedCells]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]); 


    return (
        <div className="overflow-x-hidden">
            <div className="pt-2 shadow-b mb-0 flex justify-between items-center rajdhani-font">
                <button onClick={handleBackClick} className="btn btn-ghost">
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </button>
                <div className="flex items-center justify-center flex-grow">
                    <img src={cliqueImage} alt="Clique" className="h-8 mr-2 dark:invert" />
                    <span className="font-bold text-xl">Clique</span>
                </div>
                {/* <button onClick={() => setIsInfoModalOpen(true)} className="ml-auto mr-2">
                    <FontAwesomeIcon icon={faCircleInfo} className="text-3xl" />
                </button> */}
            </div>
            <div className="relative">
                <div className="relative flex justify-center my-4">
                    <div className="inline-flex items-stretch rounded-lg border-2 border-secondary overflow-hidden">
                        <div className="bg-secondary text-black px-3 flex items-center text-md">
                            Theme:
                        </div>
                        <div className="bg-white text-black px-4 py-2 text-lg font-medium">
                            {data.theme}
                        </div>
                    </div>
                    <button 
                        onClick={() => setIsInfoModalOpen(true)} 
                        className="absolute right-0 top-1/2 -translate-y-1/2 mr-4"
                    >
                        <FontAwesomeIcon icon={faCircleInfo} className="text-3xl" />
                    </button>
                </div>
                <div className="flex justify-between items-center mt-4 mb-4 h-16">
                    <div className="w-1/2 pl-10 flex justify-center items-center h-full">
                        <div className="p-4 rounded w-full text-center flex justify-center items-center">
                            {leftSideContent}
                        </div>
                    </div>
                    <div className="w-1/2 pr-10 flex justify-center items-center h-full">
                    <div 
                        className={`tooltip tooltip-bottom tooltip-secondary ${
                            (isHintActive && foundWords.size >= (numHints + 1) * 2) || 
                            (!isHintActive && foundWords.size < (numHints + 1) * 2) 
                            ? 'before:w-[10rem] before:content-[attr(data-tip)]' 
                            : ''
                        }`} 
                        data-tip={
                            isHintActive && foundWords.size >= (numHints + 1) * 2
                            ? "Use the current hint before requesting another"
                            : !isHintActive && foundWords.size < (numHints + 1) * 2
                            ? "Find two non-theme words to get a hint!"
                            : null
                        }
                    >
                    <button 
                            className="btn btn-outline btn-primary rounded-full btn-md text-lg px-4 relative overflow-hidden border-2 disabled:bg-white w-32"
                            disabled={foundWords.size < (numHints + 1) * 2} 
                            style={{
                                backgroundImage: 'linear-gradient(to right, #e6e6fa 100%, transparent 100%)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: foundWords.size > (numHints * 2) + 1 ? '100% 100%' :
                                    foundWords.size === (numHints * 2) + 1 ? '50% 100%' : '0% 100%',
                                transition: 'background-size 0.5s ease-out',
                            }}
                            onClick={() => {
                                if (!isHintActive) {
                                    handleHintClick();
                                } 
                              }}
                        >
                            <span className={`relative z-10 text-xl rajdhani-font font-bold ${foundWords.size >= (numHints + 1) * 2 ? 'dark:text-black' : 'dark:text-gray-400'}`}>
                                Hint
                            </span>
                            {foundWords.size === (numHints * 2) + 1 && (
                                <div 
                                    className="absolute inset-0 pointer-events-none" 
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: '3px',
                                        borderColor: '#7d79d2 transparent #7d79d2 #7d79d2',
                                        borderRadius: '9999px 0 0 9999px',
                                        width: '50%'
                                    }}
                                />
                            )}
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <AnimatedOval word={animatingWord} isAnimating={isAnimating} originY="top-1/4" />
            {puzzle && puzzle.length > 0 && basePuzzle ? (
                <div className="mb-4">
                    {puzzle.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex justify-center mb-1">
                            <div 
                                className={`rounded-lg px-4 py-2 flex justify-center ${
                                    shakingRow === rowIndex
                                        ? 'bg-error'
                                        : rowIndex === hintRowIndex
                                            ? 'bg-yellow-300'
                                            : 'bg-gray-300'
                                }`} 
                                style={pulsingRow === rowIndex ? rowPulseStyles : {}}
                            >
                                {row.map((letter, letterIndex) => (
                                    <CompletedLetter 
                                        key={letterIndex} 
                                        letter={letter} 
                                        onClick={() => handleLetterClick(rowIndex, letterIndex)}
                                        isActive={rowIndex === activeLetter.row && letterIndex === activeLetter.col}
                                        isUserEntered={basePuzzle[rowIndex][letterIndex] === null && letter !== null}
                                        isHighlighted={highlightedCells.has(JSON.stringify({ row: rowIndex, col: letterIndex }))}
                                        rowIndex={rowIndex}
                                        letterIndex={letterIndex}
                                        rowLength={row.length}
                                        style={pulsingLetter && pulsingLetter.rowIndex === rowIndex && pulsingLetter.letterIndex === letterIndex ? letterPulseStyles : {}} 
                                        isShaking={shakingRow === rowIndex}
                                        isDropping={droppingLetter && droppingLetter.row === rowIndex && droppingLetter.col === letterIndex}
                                        foundClique={foundClique}
                                        isCliqueLetter={highlightedCells.has(JSON.stringify({ row: rowIndex, col: letterIndex }))}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ) : !dataLoadFailed ? (
                <div className="flex flex-col items-center justify-center">
                <h2 className="text-2xl font-bold mb-4">Loading</h2>
                <span className="loading loading-dots loading-lg"></span>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center text-center">
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">There was an error loading the game.</h2>
                    <FontAwesomeIcon icon={faFaceDisappointed} className="text-4xl mt-4" />
                    <h2 className="text-xl font-bold mb-4 mt-8 px-16">Please try again later.</h2>
                </div>
            )}
            { letters && letters.length > 0 ? (
                <div className="grid grid-cols-7 gap-2 pt-2 pb-4 w-[320px] mx-auto draggable-container relative">
                    {letters.map((letterObj, index) => (
                        <button
                            key={index}
                            className={`shadow-lg w-10 h-12 flex items-center justify-center text-xl font-bold rounded-md ${
                            letterObj.used
                                ? 'bg-gray-300 cursor-not-allowed'
                                : letterObj.highlighted
                                ? 'bg-yellow-300'
                                : 'bg-blue-300'
                            } ${letterObj.used ? 'dark:text-gray-500' : 'dark:text-black'}`}
                            style={{
                            userSelect: 'none',
                            touchAction: 'none',
                            ...(returnedLetterIndex === index ? letterPulseStyles : {})
                            }}
                            onClick={() => !letterObj.used && handleTileClick(letterObj, index)}
                            disabled={letterObj.used}
                        >
                            {letterObj.letter}
                        </button>
                    ))}
                    {Array.from({ length: 20 - letters.length }).map((_, index) => (
                    <div key={`empty-${index}`} className="w-10 h-12" />
                    ))}
                    {!isGameCompleted && (
                    <button 
                        onClick={removeActiveLetter} 
                        className={`btn bg-blue-500 text-white dark:text-black ${letters.length === 20 ? 'w-10' : 'w-16'} h-12 flex items-center justify-center rounded-md absolute bottom-4 right-0`}>
                        <FontAwesomeIcon icon={faDeleteLeft} className="text-2xl" />
                    </button>
                    )}
                </div>
            ) : null}
            <CompletedModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} game="clique" shareText={shareString} currentDate={currentDate} onDateChange={handleDateChange} />
            <InfoModal isOpen={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)} />
            {gameWon && <RatingAlert/>}
        </div>
    );
};

export default Clique;