import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function Feedback() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, email, message, app: "PuzPop" };

    try {
      const response = await fetch('https://v4ag3j7ei7.execute-api.us-east-1.amazonaws.com/prod/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        console.error('Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-base-200">
      <div className="navbar bg-base-100 shadow-lg">
        <div className="flex-none">
          <Link to="/" className="btn btn-square btn-ghost">
            <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
          </Link>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="card w-full max-w-2xl mx-auto shadow-2xl bg-base-100 border-2 border-primary">
          {!isSubmitted ? (
            <>
              <div className="text-center mb-4">
                <h1 className="text-2xl font-bold pt-4 rajdhani-font">Submit Feedback</h1>
                <p className="pt-4 px-12 text-lg">
                  Do you have any feedback or just want to say hi? Use the contact form below and I will get back to you!
                </p>
              </div>
              <form onSubmit={handleSubmit} className="card-body">
                <div className="form-control px-4">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your name"
                    className="input input-bordered"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-control px-4">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Your email"
                    className="input input-bordered"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-control px-4">
                  <label className="label">
                    <span className="label-text">Message</span>
                  </label>
                  <textarea
                    placeholder="Your message"
                    className="textarea textarea-bordered h-24"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="form-control mt-6 pb-4 pl-4 pr-4">
                  <button type="submit" className="btn btn-primary text-white">Submit</button>
                </div>
              </form>
            </>
          ) : (
            <div className="card-body text-center">
              <h2 className="text-2xl font-bold rajdhani-font pt-4 pr-4 pl-4">Thank you for the feedback!</h2>
              <FontAwesomeIcon icon={faCheckCircle} className="text-5xl text-success mt-4 pb-4" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Feedback;