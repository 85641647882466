// playHistoryUtil.js
import { Preferences } from '@capacitor/preferences';

export const getPlayHistory = async () => {
  const { value } = await Preferences.get({ key: "playHistory" });
  return value ? JSON.parse(value) : {};
};

export const updatePlayHistory = async (date, game, status) => {
  const playHistory = await getPlayHistory();
  if (!playHistory[date]) {
    playHistory[date] = {};
  }
  playHistory[date][game] = status;
  await Preferences.set({
    key: "playHistory",
    value: JSON.stringify(playHistory)
  });
};

export const getGameStatus = async (date, game) => {
  const playHistory = await getPlayHistory();
  return playHistory[date] && playHistory[date][game] ? playHistory[date][game] : null;
};