// Header.js
import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSunBright, faEllipsisVertical } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import circleChevronLeft from '../assets/chevron-left-solid.svg';
import circleChevronRight from '../assets/chevron-right-solid.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AuthModal from './AuthModal';
import { Link } from 'react-router-dom';
import { useNewYorkDate, isFirstDateLess, getPrevDate, getNextDate, formatDate, formatToYYYYMMDD, isTwoPlusDaysAgo, formatToDateObj } from './DateTime';

function Header({ currentDate, setCurrentDate, toggleTheme, theme, user, signOut, openAuthModal, onAuthStateChange }) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const minDate = '2024-08-01'
  const maxDate =  useNewYorkDate();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { day, date } = formatDate(currentDate);

  const handleChevronClick = (direction) => {
    if (direction === "left") {
      if (isTwoPlusDaysAgo(getPrevDate(currentDate), maxDate) && !user) {
        openAuthModal();
      } else if (isFirstDateLess(minDate, currentDate)) {
        setCurrentDate(getPrevDate(currentDate));
      }
    } else if (direction === "right") {
      if (isFirstDateLess(currentDate, maxDate)) {
        setCurrentDate(getNextDate(currentDate));
      }
    }
  }

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDateChange = (date) => {
    const convDate = formatToYYYYMMDD(date)
    if (convDate === currentDate) {
      setIsDatePickerOpen(false);
      return;
    }
    if (isTwoPlusDaysAgo(convDate, maxDate) && !user) {
      openAuthModal();
    } else {
      setCurrentDate(convDate);
    }
    setIsDatePickerOpen(false);
  };

  const handleSignIn = () => {
    openAuthModal();
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      onAuthStateChange();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const customDatePickerStyles = `
    .custom-datepicker {
      font-family: 'Rajdhani', sans-serif;
    }
    .custom-datepicker .react-datepicker__header {
      background-color: #e6e6fa;
      border-bottom: 1px solid hsl(var(--s));
    }
    .custom-datepicker .react-datepicker__current-month,
    .custom-datepicker .react-datepicker__day-name {
      color: hsl(var(--sc));
    }
    .custom-datepicker .react-datepicker__current-month {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .custom-datepicker .react-datepicker__day-name, 
    .custom-datepicker .react-datepicker__day {
      width: 2.5rem;
      line-height: 2.5rem;
      margin: 0.2rem;
      font-size: 1.2rem;
    }
    .custom-datepicker .react-datepicker__day--selected {
      background-color: #7d79d2;
      color: hsl(var(--pc));
    }
    .custom-datepicker .react-datepicker__day:hover {
      background-color: #e6e6fa;
      color: black;
    }
    .custom-datepicker .custom-header-button:disabled {
      background-color: #e6e6fa;
      cursor: not-allowed;
    }
  `;

  return (
    <div className="bg-base-100" style={{boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', position: 'relative'}}>
      <style>{customDatePickerStyles}</style>
      {/* <div className="navbar pb-0" >
          <div className="navbar-start">
          </div>
          <div className="navbar-center flex items-center justify-center space-x-2">
            <FontAwesomeIcon icon={faNewspaper} style={{ fontSize: '24px' }} />
            <span className="text-xl font-custom font-bold">THE METRO GAZETTE</span>
          </div>
          <div className="navbar-end pl-1">
            <button className="btn btn-sm btn-outline border-gray-400 btn-circle">
              <FontAwesomeIcon icon={faMagnifyingGlass} className="text-faded text-sm" />
            </button>
          </div>
      </div> */}
      <div className="navbar pb-0" >
          <div className="navbar-start">
            <button 
              onClick={toggleTheme}
              className="btn btn-ghost btn-circle"
              aria-label="Toggle theme"
            >
              <FontAwesomeIcon 
                icon={theme === 'dark' ? faSunBright : faMoon} 
                className="text-xl"
              />
            </button>
          </div>
          <div className="navbar-center flex items-center justify-center space-x-2">
            <img src="/puzpop.svg" alt="PuzPop Logo" className="h-8 w-8 mr-2" /> 
            <span className="text-3xl rajdhani-font font-bold">PuzPop!</span>
          </div>
          <div className="navbar-end pl-1 flex items-center">
          <div className="dropdown dropdown-end rajdhani-font">
            <button 
              tabIndex="0" 
              className="btn btn-ghost btn-circle"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} className="text-3xl" />
            </button>
            {isMenuOpen && (
              <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50 text-xl">
                <li>
                  <Link 
                    to="/feedback" 
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Submit Feedback
                  </Link>
                </li>
                <li>
                  {user ? (
                    <button onClick={handleSignOut}>Sign Out</button>
                  ) : (
                    <button onClick={handleSignIn}>Sign In</button>
                  )}
                </li>
              </ul>
            )}
          </div>
      </div>
      </div>
      <div className="navbar pt-0 pb-0">
          <div className="navbar-start pl-8">
            <button 
              className="btn btn-sm btn-circle btn-primary group disabled:!bg-secondary disabled:!text-secondary-content disabled:!border-secondary dark:disabled:!bg-custom-disabled" 
              onClick={() => handleChevronClick("left")}
              disabled={currentDate <= minDate}
            >
                <img src={circleChevronLeft} alt="Left" className="w-3 h-3" />
            </button>
          </div>
          <div className="navbar-center">
            <button 
              className="btn btn-outline btn-sm text-lg border-gray-400 ml-1 mr-1 rounded-full hover:bg-transparent hover:scale-105 transform transition-transform duration-200"
              onClick={toggleDatePicker}
            >
            <span className="font-bold text-black dark:text-white" style={{ fontFamily: 'Rajdhani, sans-serif' }}>{day},</span>
                <span className="text-gray-400" style={{ fontFamily: 'Rajdhani, sans-serif' }}> {date}</span>
                <i className="fas fa-chevron-down text-gray-400 hover:text-gray-400 text-sm"></i>
            </button>
            {isDatePickerOpen && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 z-10 mt-2">
                <DatePicker
                  selected={formatToDateObj(currentDate)}
                  onSelect={handleDateChange}
                  onChange={handleDateChange}
                  minDate={formatToDateObj(minDate)}
                  maxDate={formatToDateObj(maxDate)}
                  inline
                  calendarClassName="custom-datepicker bg-base-100 rounded-lg shadow-xl"
                  dayClassName={() => "text-center hover:bg-primary hover:text-white"}
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="flex items-center justify-between px-2 py-2">
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className={`custom-header-button btn btn-sm btn-ghost text-secondary-content ${prevMonthButtonDisabled ? 'background-secondary' : ''}`}>
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      <span className="react-datepicker__current-month font-bold">
                        {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                      </span>
                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className={`custom-header-button btn btn-sm btn-ghost text-secondary-content ${nextMonthButtonDisabled ? 'background-secondary' : ''}`}>
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  )}
                />
              </div>
            )}
          </div>
          <div className="navbar-end pr-8">
            <button 
              className="btn btn-sm btn-circle btn-primary group disabled:!bg-secondary disabled:!text-secondary-content disabled:!border-secondary dark:disabled:!bg-custom-disabled" 
              onClick={() => handleChevronClick("right")} 
              disabled={currentDate >= maxDate}
            >
                <img src={circleChevronRight} alt="Right" className="w-3 h-3" />
            </button>
          </div>
      </div>
      <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)} />
    </div>
  );
}

export default Header;
