// DateTime.js
import { useState, useEffect } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { parse, format, subDays, addDays, differenceInDays } from 'date-fns';

export const useNewYorkDate = () => {
  const [nyDate, setNyDate] = useState('');
  
  useEffect(() => {
    const updateDate = () => {
      const now = new Date();
      const formattedDate = formatInTimeZone(now, 'America/New_York', 'yyyy-MM-dd');
      setNyDate(formattedDate);
    };
    updateDate();
    const intervalId = setInterval(updateDate, 10000);
    return () => clearInterval(intervalId);
  }, []);
  return nyDate;
};

export const getNewYorkDate = () => {
  const now = new Date();
  const formattedDate = formatInTimeZone(now, 'America/New_York', 'yyyy-MM-dd');
  return formattedDate;
};

export const checkIfToday = (currentDate) => {
  const now = new Date();
  const formattedDate = formatInTimeZone(now, 'America/New_York', 'yyyy-MM-dd');
  return currentDate === formattedDate;
}

export const getPrevDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  const yesterday = subDays(date, 1);
  return format(yesterday, 'yyyy-MM-dd');
};

export const getNextDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  const tomorrow = addDays(date, 1);
  return format(tomorrow, 'yyyy-MM-dd');
};

export const isFirstDateLess = (date1, date2) => {
  const parsedDate1 = parse(date1, 'yyyy-MM-dd', new Date());
  const parsedDate2 = parse(date2, 'yyyy-MM-dd', new Date());
  return parsedDate1 < parsedDate2;
};

export const isTwoPlusDaysAgo = (dateString, currentDate) => {
  const givenDate = parse(dateString, 'yyyy-MM-dd', new Date());
  const parsedCurrentDate = parse(currentDate, 'yyyy-MM-dd', new Date());
  const daysDifference = differenceInDays(parsedCurrentDate, givenDate);
  return daysDifference >= 2;
};
export const useIsTwoPlusDaysAgo = (dateString) => {
  const currentNYDate = useNewYorkDate();
  return isTwoPlusDaysAgo(dateString, currentNYDate);
};

export const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  const day = format(date, 'EEEE');
  const formattedDate = format(date, 'MMMM d');
  return { day, date: formattedDate };
};

export const formatToYYYYMMDD = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatToDateObj = (dateString) => {
  return parse(dateString, 'yyyy-MM-dd', new Date());
};