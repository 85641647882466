import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faLightCalendarCircleArrowLeft } from '@awesome.me/kit-636b1434d3/icons/kit/custom';
import Confetti from 'react-confetti';
import bigCrossword from '../assets/Big-Crossword.png';
import miniCrossword from '../assets/Mini-Crossword.png';
import lightbulbOnLight from '../assets/lightbulb-on-light.png';
import sudoku from '../assets/Sudoku.png';
import clique from '../assets/square-ellipsis-light.png';
import proximity from '../assets/bullseye-arrow-light.png';
import { gameStartDates } from './gameConstants';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import GlobalStatsChart from './RidellaGlobalStats';
import { useNewYorkDate, getPrevDate, isFirstDateLess } from './DateTime';
import { formatInTimeZone } from 'date-fns-tz';

const ConfettiOverlay = ({ isOpen }) => {
    const [showConfetti, setShowConfetti] = useState(isOpen);
  
    useEffect(() => {
      if (isOpen) {
        setShowConfetti(true);
        const timer = setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [isOpen]);
  
    return showConfetti ? <Confetti /> : null;
  };

const CompletedModal = ({ isOpen, onClose, game, shareText, currentDate, onDateChange, completionTime }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const navigate = useNavigate();
    const [availableGames, setAvailableGames] = useState([]);
    const [isWeb, setIsWeb] = useState(false);
    const [activeTab, setActiveTab] = useState('gameComplete');
    const nyDate = useNewYorkDate();

    useEffect(() => {
        setIsWeb(Capacitor.getPlatform() === 'web');
    }, []);

      const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareText);
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 2000);
            actionCounter(game, "shareButton");
        } catch (err) {
            setCopySuccess('Failed to copy');
        }
    };

    useEffect(() => {
        if (isOpen) {
          const fetchPlayHistory = async () => {
            try {
              const { value } = await Preferences.get({ key: 'playHistory' });
              if (value) {
                const playHistory = JSON.parse(value);
                const todayHistory = playHistory[nyDate] || {};
          
                const allGames = ['crossword', 'mini', 'ridella', 'clique', 'proximity', 'sudoku'];
                const uncompletedGames = allGames.filter(g => todayHistory[g] !== 'completed' && g !== game);
                setAvailableGames(uncompletedGames);
              }
            } catch (error) {
              console.error('Error fetching play history:', error);
            }
          };
      
          fetchPlayHistory();
        }
      }, [isOpen, game, nyDate]);

      const installAppComponent = (gameName) => {
        return (
          <div className="max-w-3xl mx-auto">
            <div className="relative">
              <h3 className="font-bold text-2xl text-center pt-4">
                {gameName} now has a mobile app!
              </h3>
            </div>
            <div className="flex flex-row mt-4">
              <div className="flex-1">
                <p className="mb-2">Hey {gameName} fans!</p>
                <p className="mb-2">
                  Over the last few months, I have been hard at work on a mobile app for {gameName} and a suite of other games.
                  The app is called PuzPop and it is now available on the App Store and Google Play.
                </p>
                <p className="mb-2">PuzPop includes:</p>
                <ul className="list-disc list-inside mb-4">
                  <li>Ridella</li>
                  <li>Clique</li>
                  <li>Proximity</li>
                  <li>Crossword puzzles (in Midi and Mini sizes)</li>
                  <li>Sudoku</li>
                </ul>
                <p className="mb-2">
                  Give it a download and a review - and feel free to send any feedback using{' '}
                  <a href="https://ridella.xyz/contact" target="_blank" rel="noopener noreferrer" className="link link-primary">
                    the contact form
                  </a>. 
                  Thanks for playing!
                </p>
                <p>- Dylan</p>
              </div>
            </div>
            <div className="flex justify-center mt-6 mb-4">
              <a 
                href="https://apps.apple.com/us/app/puzpop/id6670177208"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <img 
                  src="/Download_on_the_App_Store.svg" 
                  alt="Download on the App Store" 
                  className="h-10"
                />
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=com.puzpop.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src="/GetItOnGooglePlay.png" 
                  alt="Get it on Google Play" 
                  className="h-10"
                />
              </a>
            </div>
          </div>
        );
      };

    const getGameIcon = (gameName) => {
        switch(gameName) {
            case 'crossword':
                return bigCrossword;
            case 'mini':
                return miniCrossword;
            case 'ridella':
                return lightbulbOnLight;
            case 'sudoku':
                return sudoku;
            case 'clique':
                return clique;
            case 'proximity':
                return proximity;
            default:
                return null;
        }
    };

    const PlayPreviousDayButton = ({ gameName, currentDate, onDateChange }) => {
        const [targetDate, setTargetDate] = useState(null);
        const gameStartDate = gameStartDates[gameName.toLowerCase()];
    
        useEffect(() => {
            const findFirstUncompletedDay = async () => {
                try {
                    const { value } = await Preferences.get({ key: 'playHistory' });
                    if (!value) {
                        setTargetDate(getPrevDate(currentDate));
                        return;
                    }
                    const playHistory = JSON.parse(value);
                    let checkDate = currentDate;
                    let foundDate = null;
                    while (!foundDate) {
                        checkDate = getPrevDate(checkDate);
                        if (isFirstDateLess(checkDate, gameStartDate)) {
                            break;
                        }
                        const dateHistory = playHistory[formatInTimeZone(checkDate, 'America/New_York', 'yyyy-MM-dd')];
                        if (!dateHistory || dateHistory[gameName.toLowerCase()] !== 'completed') {
                            foundDate = checkDate;
                            break;
                        }
                    }
                    setTargetDate(foundDate);
                } catch (error) {
                    console.error('Error fetching play history:', error);
                    setTargetDate(getPrevDate(currentDate));
                }
            };
    
            findFirstUncompletedDay();
        }, [currentDate, gameName, gameStartDate]);
    
        if (!targetDate || isFirstDateLess(targetDate, gameStartDate)) {
            return null;
        }
    
        const handleClick = (e) => {
            e.preventDefault();
            onClose();
            
            const isMiniOrCrossword = ['mini', 'crossword'].includes(gameName.toLowerCase());
            const dateParam = formatInTimeZone(targetDate, 'America/New_York', 'yyyy-MM-dd');
            
            const url = isMiniOrCrossword
                ? `/crossword?type=${gameName}&date=${dateParam}`
                : `/${gameName}?date=${dateParam}`;
            
            navigate(url);
            onDateChange(targetDate);
        };
    
        return (
            <button 
                onClick={handleClick}
                className="btn btn-outline text-black text-xl rounded-full mt-4 w-full max-w-[300px] flex items-center justify-center dark:bg-white"
            >
                <FontAwesomeIcon icon={faLightCalendarCircleArrowLeft} />
                Play Previous {gameName.charAt(0).toUpperCase() + gameName.slice(1)}
            </button>
        );
    };

    const getGameBackgroundColor = (gameName) => {
        switch(gameName) {
            case 'crossword':
                return "#ecd4a6";
            case 'mini':
                return "#f68ea6";
            case 'ridella':
                return "#C2C1EB";
            case 'clique':
                return "#A2D0D0";
            case 'proximity':
                return "#a4c6eb";
            case 'sudoku':
                return "#a1d0a6";
            default:
                return "#ffffff";
        }
    };

    const PlayOtherGameButton = ({ gameName }) => {
        const backgroundColor = getGameBackgroundColor(gameName);
        const isMiniOrCrossword = ['mini', 'crossword'].includes(gameName.toLowerCase());
        
        const gameUrl = isMiniOrCrossword
            ? `/crossword?type=${gameName}`
            : `/${gameName}`;
    
        return (
            <div className="flex justify-center w-full">
                <Link 
                    to={gameUrl} 
                    className="btn text-black text-xl rounded-full mt-4 w-full max-w-[300px] flex items-center justify-center"
                    style={{ backgroundColor }}
                >
                    <img src={getGameIcon(gameName)} alt={`${gameName} icon`} className="w-6 h-6 mr-2" />
                    Play {gameName.charAt(0).toUpperCase() + gameName.slice(1)}
                </Link>
            </div>
        );
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const renderModalContent = () => {
        const heading = (
            <>
                <FontAwesomeIcon icon={faCrown} className="text-6xl pb-4" />
                <h2 className="text-4xl font-bold mb-4">Well Done!</h2>
            </>
        );

        const shareComponent = (
            <div className="bg-gray-200 rounded">
                <div className="p-2 rounded text-lg dark:text-black">
                    {shareText}
                </div>
                <button 
                    className="btn btn-primary text-white text-2xl rounded-full mt-4 mb-4 w-48" 
                    onClick={copyToClipboard}
                >
                    {copySuccess || 'Share Results'}
                </button>
            </div>
        );

        const contactComponent = (
            <div className="flex flex-col items-center">
                <p className="text-lg mt-4 mb-0 text-center">
                    Any bugs to report or thoughts on the game? Please get in touch using the feedback form.
                </p>
                <Link 
                    to="/feedback" 
                    className="btn bg-black text-white text-2xl rounded-full mt-4 w-full max-w-[300px]"
                >
                    Feedback
                </Link>
            </div>
        );

        const playOtherGameComponent = availableGames.length > 0 ? (
            <PlayOtherGameButton gameName={availableGames[Math.floor(Math.random() * availableGames.length)]} />
        ) : null;

        const playPreviousDayComponent = (
            <div className="flex justify-center w-full">
                <PlayPreviousDayButton gameName={game} currentDate={currentDate} onDateChange={onDateChange}/>
            </div>
        );

        if (game === 'ridella' && currentDate === nyDate) {
            return (
                <>
                    <div className="tabs w-full mb-4 pr-14"> {/* Added pr-14 for padding-right */}
                        <button 
                            className={`tab tab-lifted flex-1 text-lg font-bold ${
                                activeTab === 'gameComplete' 
                                    ? 'tab-active bg-base-100 border-primary border-b-0' 
                                    : 'bg-gray-200 text-gray-500'
                            }`}
                            onClick={() => setActiveTab('gameComplete')}
                        >
                            Results
                        </button>
                        <button 
                            className={`tab tab-lifted flex-1 text-lg font-bold ${
                                activeTab === 'globalStats' 
                                    ? 'tab-active bg-base-100 border-primary border-b-0' 
                                    : 'bg-gray-200 text-gray-500'
                            }`}
                            onClick={() => setActiveTab('globalStats')}
                        >
                            Global Stats
                        </button>
                    </div>
                    <div className="min-h-[450px]">
                        {activeTab === 'gameComplete' ? (
                            <>
                                {heading}
                                {shareComponent}
                                {playPreviousDayComponent}
                                {playOtherGameComponent}
                            </>
                        ) : (
                            <GlobalStatsChart />
                        )}
                    </div>
                </>
            );
        }

        switch(game) {
            case 'crossword':
                return (
                    <>
                        {heading}
                        <p className="text-xl">
                            You completed the crossword in {formatTime(completionTime)}!
                        </p>
                        {contactComponent}
                        {playPreviousDayComponent}
                        {playOtherGameComponent}
                    </>
                );
            case 'mini':
                return (
                    <>
                        {heading}
                        <p className="text-xl">
                            You completed the Mini in {formatTime(completionTime)}!
                        </p>
                        {contactComponent}
                        {playPreviousDayComponent}
                        {playOtherGameComponent}
                    </>
                );
            case 'ridella':
                return (
                    <>
                        {heading}
                        {shareComponent}
                        {playPreviousDayComponent}
                        {playOtherGameComponent}
                    </>
                );
            case 'clique':
                if (isWeb) {
                    return (
                        <>
                            {heading}
                            {installAppComponent("Clique")}
                        </>
                    );
                } else {
                    return (
                        <>
                            {heading}
                            {shareComponent}
                            {playPreviousDayComponent}
                            {playOtherGameComponent}
                        </>
                    );
                }
            case 'proximity':
                if (isWeb) {
                    return (
                        <>
                            {heading}
                            {installAppComponent("Proximity")}
                        </>
                    );
                } else {
                    return (
                        <>
                            {heading}
                            {shareComponent}
                            {playPreviousDayComponent}
                            {playOtherGameComponent}
                        </>
                    );
                }
            case 'sudoku':
                return (
                    <>
                        {heading}
                        <p className="text-xl">
                            You completed the Sudoku in {formatTime(completionTime)}!
                        </p>
                        {contactComponent}
                        {playOtherGameComponent}
                    </>
                );
            default:
                return (
                    <>
                        {heading}
                        <p>Game completed!</p>
                        {contactComponent}
                    </>
                );
        }
    };

    return (
        <>
            {isOpen && (
                <div>
                    <ConfettiOverlay isOpen={isOpen} />
                    <input type="checkbox" id="my-modal-4" className="modal-toggle" checked readOnly />
                    <div className="modal" onClick={onClose}>
                        <div className="modal-box relative flex flex-col justify-center" onClick={(e) => e.stopPropagation()}>
                            <label
                                htmlFor="my-modal-4"
                                className="btn btn-ghost btn-lg btn-circle absolute right-2 top-2"
                                onClick={onClose}
                            >
                                <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                            </label>
                            <div className="text-center">
                                {renderModalContent()}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

function actionCounter(game, userAction) {
    const apiUrl = "https://ij5h004wz9.execute-api.us-east-1.amazonaws.com/prod";
    const params = {
        game: game,
        action: userAction
    };
    fetch(apiUrl, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .catch(error => {
      console.error('Error incrementing counter:', error);
    });
  }

export default CompletedModal;
