// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles
import TheWeek from './components/TheWeek';
import Home from './components/Home';
// import Footer from './components/Footer';
import Mini from './components/Mini';
// import Header from './components/Header';
import Sudoku from './components/Sudoku'
import Ridella from './components/Ridella'
// import Chrono from './components/Chrono'
// import JoinedWords from './components/JoinedWords'
import Clique from './components/Clique'
import Proximity from './components/Proximity'
import Feedback from './components/Feedback'
import './aws-exports';
import AuthModal from './components/AuthModal';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { PushNotifications } from '@capacitor/push-notifications';
import axios from 'axios';
import { getStoredUserId } from './components/Userid';
import { Hub } from 'aws-amplify/utils';
import { Preferences } from '@capacitor/preferences';
import { StatusBar, Style, Animation } from '@capacitor/status-bar';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

function App() {
  const [theme, setTheme] = useState('cupcake');
  const [user, setUser] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const uploadUsernameToDatabase = useCallback(async (username) => {
    console.log("uploading username")
    const { value: userid } = await Preferences.get({ key: 'userid' });
    try {
        const response = await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
            username: username,
            altID: userid || null
        });
        console.log('username uploaded successfully:', response.data);
    } catch (error) {
        console.error('Error uploading userId:', error);
    }
    if (userid) {
      try {
        const response = await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
            username: userid,
            altID: username
        });
        console.log('username uploaded successfully:', response.data);
      } catch (error) {
          console.error('Error uploading userId:', error);
      }
    }
  }, []);



  const initializePushNotifications = async () => {
    try {
      const result = await PushNotifications.requestPermissions();
      if (result.receive === 'granted') {
        await PushNotifications.register();

        PushNotifications.addListener('registration', async (token) => {
          console.log('Push registration success, token: ' + token.value);
          
          // Retry getting the user ID with a delay if it's not immediately available
          const getUserId = async (retries = 5, delay = 1000) => {
            for (let i = 0; i < retries; i++) {
              const userid = await getStoredUserId();
              if (userid) return userid;
              await new Promise(resolve => setTimeout(resolve, delay));
            }
            return null;
          };

          const userid = await getUserId();
          
          if (userid) {
            try {
              const response = await axios.post('https://v7z3x44mg5.execute-api.us-east-1.amazonaws.com/prod', {
                username: userid,
                pushToken: token.value
              });
              console.log('Push token uploaded successfully:', response.data);
            } catch (error) {
              console.error('Error uploading push token:', error);
            }
          } else {
            console.error('Failed to retrieve user ID after multiple attempts');
          }
        });

        PushNotifications.addListener('registrationError', (error) => {
          console.error('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener('pushNotificationReceived', (notification) => {
          console.log('Push received: ' + JSON.stringify(notification));
          // Handle the received notification
        });

        PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
          // Handle the action performed on the notification
        });
      }
    } catch (error) {
      console.error('Error initializing push notifications:', error);
    }
  };

  const handleAuthStateChange = useCallback(async (authState) => {
    console.log("Auth state changed:", authState);
    try {
      const currentUser = await getCurrentUser();
      console.log("currentUser", currentUser)
      setUser(currentUser);
      if (currentUser && currentUser.username) {
        const { value: storedUsername } = await Preferences.get({ key: 'username' });
        if (storedUsername !== currentUser.username) {
          await Preferences.set({ key: 'username', value: currentUser.username });
          uploadUsernameToDatabase(currentUser.username);
        }
      }
    } catch (error) {
      setUser(null);
    }
  }, [uploadUsernameToDatabase]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const backHandler = CapacitorApp.addListener('backButton', handleBackButton);
  
      return () => {
        backHandler.remove();
      };
    }
  }, []);
  
  const handleBackButton = () => {
    const currentPath = window.location.pathname;
    if (currentPath !== '/') {
      window.history.pushState({}, '', '/');
      window.dispatchEvent(new PopStateEvent('popstate'));
    } else {
      if (Capacitor.isNativePlatform()) {
        CapacitorApp.exitApp();
      }
    }
  };

  useEffect(() => {
    const loadTheme = async () => {
      const { value: savedTheme } = await Preferences.get({ key: 'theme' });
      const themeToUse = savedTheme || 'cupcake';
      setTheme(themeToUse);
      document.documentElement.setAttribute('data-theme', themeToUse);
      if (Capacitor.isNativePlatform()) {
        try {
          if (themeToUse === 'dark') {
            await StatusBar.setStyle({ style: Style.Dark });
          } else {
            await StatusBar.setStyle({ style: Style.Light });
          }
        } catch (error) {
          console.error('Status bar error:', error);
        }
      }
    };  
    loadTheme();
    handleAuthStateChange();
    if (Capacitor.isNativePlatform()) {
      initializePushNotifications();
    }
    const unsubscribe = Hub.listen('auth', handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  const toggleTheme = async () => {
    const newTheme = theme === 'cupcake' ? 'dark' : 'cupcake';
    setTheme(newTheme);
    await Preferences.set({ key: 'theme', value: newTheme });
    document.documentElement.setAttribute('data-theme', newTheme);
    if (Capacitor.isNativePlatform()) {
      try {
        if (newTheme === 'dark') {
          await StatusBar.setStyle({ style: Style.Dark });
        } else {
          await StatusBar.setStyle({ style: Style.Light });
        }
      } catch (error) {
        console.error('Status bar toggle error:', error);
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  const [statusBarHeight, setStatusBarHeight] = useState(() => {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      return window.devicePixelRatio >= 3 ? 47 : 20;
    }
    return 0;
  });

  useEffect(() => {
    const initializeStatusBar = async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          await StatusBar.show({ animation: Animation.Fade });
          const info = await StatusBar.getInfo();
          if (Capacitor.getPlatform() === 'ios') {
            const height = info.visible ? (window.devicePixelRatio >= 3 ? 47 : 20) : 0;
            setStatusBarHeight(height);
            document.documentElement.style.setProperty(
              '--safe-area-inset-top',
              `${height}px`
            );
          }
        } catch (error) {
          console.warn('Status bar initialization error:', error);
        }
      }
    };
    initializeStatusBar();
    const handleResize = () => {
      initializeStatusBar();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const appStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    height: `calc(100% - ${statusBarHeight}px)`,
    paddingTop: `${statusBarHeight}px`,
  };

  return (
    <Authenticator.Provider>
    <Router>
      <ScrollToTop />
      <div className={theme} style={appStyle}>
        <Routes>
          <Route path="/" element={<Home toggleTheme={toggleTheme} theme={theme} user={user} signOut={handleSignOut}  openAuthModal={() => setIsAuthModalOpen(true)} onAuthStateChange={handleAuthStateChange} />} />
          <Route path="/theweek" element={<TheWeek />} />
          <Route path="/crossword" element={<Mini />} />
          <Route path="/sudoku" element={<Sudoku />} />
          <Route path="/ridella" element={<Ridella />} />
          {/* <Route path="/chrono" element={<Chrono />} /> */}
          {/* <Route path="/joinedwords" element={<JoinedWords />} /> */}
          <Route path="/clique" element={<Clique />} />
          <Route path="/proximity" element={<Proximity />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
        <AuthModal 
          isOpen={isAuthModalOpen} 
          onClose={() => setIsAuthModalOpen(false)} 
          onAuthStateChange={handleAuthStateChange}
        />
      </div>
    </Router>
    </Authenticator.Provider>
  );
}

export default App;
