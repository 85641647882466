import React from 'react';
import { X } from 'react-bootstrap-icons';

const Explanation = ({ data, show, handleShow, handleClose }) => {

  const handleButtonClick = () => {
    expButtonCounter();
    handleShow();
  };

  return (
    <>      
      <button 
        className="bg-white text-[#7D79D2] rounded-full w-[150px] py-2 px-0 text-center my-5 ml-2 cursor-pointer text-lg font-bold border-2 border-[#7D79D2] hover:bg-[#7D79D2] hover:text-white hover:border-[#5B5FB6] transition-colors duration-300"
        style={{ fontFamily: 'Comfortaa, sans-serif' }}
        onClick={handleButtonClick}
      >
        Explanation
      </button>
      
      {show && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-6 z-10 max-w-md w-full dark:bg-black">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Explanation</h3>
              <button 
                className="text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <X size={28} />
              </button>
            </div>
            <div className="space-y-4">
              <p><strong>{data.firstLine}</strong> - {data['1ex']}</p>
              <p><strong>{data.secondLine}</strong> - {data['2ex']}</p>
              <p><strong>{data.thirdLine}</strong> - {data['3ex']}</p>
              <p><strong>{data.fourthLine}</strong> - {data['4ex']}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function expButtonCounter() {
  const apiUrl = "https://7z7przcw81.execute-api.us-east-1.amazonaws.com/prod";
  const params = {
      action: "expButton"
  };
  fetch(apiUrl, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
  .then(response => response.json())
  .catch(error => {
    console.error('Error incrementing counter:', error);
  });
}

export default Explanation;