// AuthModal.js
import React, { useEffect, useRef, useCallback } from 'react';
import { Authenticator, useAuthenticator, ThemeProvider, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const AuthModal = ({ isOpen, onClose, onAuthStateChange }) => {
  const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);
  const modalRef = useRef();
  const { tokens } = useTheme();
  const prevAuthStatusRef = useRef(authStatus);

  const handleAuthChange = useCallback(() => {
    if (authStatus !== prevAuthStatusRef.current) {
      if (typeof onAuthStateChange === 'function') {
        onAuthStateChange();
      }
      if (authStatus === 'authenticated') {
        onClose();
      }
    }
    prevAuthStatusRef.current = authStatus;
  }, [authStatus, onAuthStateChange, onClose]);

  useEffect(() => {
    handleAuthChange();
  }, [handleAuthChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  
  const theme = {
    name: 'daisyui-custom-theme',
    tokens: {
      colors: {
        primary: {
          10: '#f4f3fb',
          20: '#e9e8f7',
          40: '#d3d1ef',
          60: '#bcb9e7',
          80: '#a6a2df',
          90: '#7d79d2',
          100: '#6a66c7',
        },
        background: {
          primary: '#faf7f5',
        },
        font: {
          interactive: '#7d79d2',
        },
        border: {
          primary: '#7d79d2',
        },
      },
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
            borderWidth: '0',
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: '#7d79d2',
            _hover: {
              backgroundColor: '#9693db',
            },
          },
          link: {
            color: '#7d79d2',
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px #bcb9e7`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral['80'],
            _active: {
              borderColor: '#7d79d2',
              color: '#6a66c7',
            },
          },
        },
      },
    },
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-base-100 pt-12 rounded-lg overflow-y-auto relative rajdhani-font">
        <button onClick={onClose} className="absolute top-2 right-2 text-xl">
          <FontAwesomeIcon icon={faTimes} className="text-2xl"/>
        </button>
        <div>
          <div className="text-center p-4">
            <h2 className="text-2xl font-bold mb-2">Sign up to play more!</h2>
            <p className="text-md">Create an account to track your stats and play past puzzles.<br/> It is completely free.</p>
          </div>
          <ThemeProvider theme={theme}>
          <Authenticator>
            {({ signOut }) => {
              if (authStatus === 'authenticated') {
                return (
                  <div>
                    <h1>Welcome, {user.username}</h1>
                    <button onClick={() => { 
                      signOut(); 
                    //   if (typeof onAuthStateChange === 'function') {
                    //     onAuthStateChange();
                    //   }
                      onClose(); 
                    }}>Sign out</button>
                  </div>
                );
              }
              return null; // Let Authenticator handle unauthenticated state
            }}
          </Authenticator>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;