import axios from 'axios';
import { Preferences } from '@capacitor/preferences';

export const loadCrosswordData = async (type, dateString) => {
    try {
        const { value: storedGamesData } = await Preferences.get({ key: "dailyGames" });
        let allGamesData = storedGamesData ? JSON.parse(storedGamesData) : {};
        if (allGamesData[dateString]?.[`${type}`]) {
            return allGamesData[dateString][`${type}`];
        }
        const endpoint = type === 'mini' 
            ? 'https://k2ppnl0d7a.execute-api.us-east-1.amazonaws.com/prod'
            : 'https://p9ruoj3lg1.execute-api.us-east-1.amazonaws.com/prod';
        const response = await axios.get(endpoint, { params: { date: dateString } });
        if (response.data.message === "No puzzle found for the specified date or fallback dates") {
            console.error('API returned an error:', response.data);
            throw new Error('API returned an error');
        }
        const newData = response.data;
        if (newData.puzzle) {
            allGamesData[dateString] = {
                ...allGamesData[dateString],
                [`${type}`]: newData
            };
            await Preferences.set({
                key: "dailyGames",
                value: JSON.stringify(allGamesData)
            });
        }
        return newData;
    } catch (error) {
        console.error('There was an error!', error);
        throw error;
    }
};

export const fetchCliqueData = async (currentDate) => {
    try {

        const { value: storedGamesData } = await Preferences.get({ key: "dailyGames" });
        let allGamesData = storedGamesData ? JSON.parse(storedGamesData) : {};

        let newData;
        if (allGamesData[currentDate]?.clique) {
            newData = allGamesData[currentDate].clique;
        } else {
            const response = await axios.get(`https://pn0cgjuyt1.execute-api.us-east-1.amazonaws.com/prod?date=${currentDate}`);
            newData = response.data;
            if (newData.puzzle) {
                allGamesData[currentDate] = {
                    ...allGamesData[currentDate],
                    clique: newData
                };
                await Preferences.set({
                    key: "dailyGames",
                    value: JSON.stringify(allGamesData)
                });
            }
        }

        const { value } = await Preferences.get({ key: 'cliqueGameStates' });
        const allGameStates = value ? JSON.parse(value) : {};
        const savedData = allGameStates[currentDate];

        return { newData, savedData };
    } catch (error) {
        console.error('There was an error fetching Clique data:', error);
        throw error;
    }
};