import React, { useState, useEffect } from 'react';
import { toZonedTime, format } from 'date-fns-tz';
import { BarChart, Bar, YAxis, XAxis, Tooltip, LabelList, ResponsiveContainer } from 'recharts';

const now = new Date();
const newYorkTime = toZonedTime(now, 'America/New_York');
const formattedDate = format(newYorkTime, 'EEEE, MMMM dd yyyy', { timeZone: 'America/New_York' });


const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-base-200 p-2 rounded shadow-lg">
                <p className="font-semibold">{`Guesses: ${payload[0].payload.name}`}</p>
                <p>{`Count: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const CustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const yPos = y + (height / 2) + 5;
    const xPos = x + width + 20;
    return <text x={xPos} y={yPos} fill="#666" fontSize={12}>{value}</text>;
};

const GlobalStatsChart = () => {
    const [chartData, setChartData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://mojbg5h5u5.execute-api.us-east-1.amazonaws.com/prod')
            .then(response => response.json())
            .then(data => {
                const formattedData = [
                    { name: '1', count: data['1Guess'] },
                    { name: '2', count: data['2Guesses'] },
                    { name: '3', count: data['3Guesses'] },
                    { name: '4', count: data['4Guesses'] },
                    { name: 'NS', count: data['didNotSolve'] }
                ];
                setChartData(formattedData);
                setTotalUsers(data['totalUsers']);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <div className="flex justify-center items-center h-64"><span className="loading loading-spinner loading-lg"></span></div>;
    }

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold text-center mb-2">Global Statistics</h2>
            <h3 className="text-lg text-center mb-4">{formattedDate}</h3>
            {chartData.length > 0 ? (
                <div className="w-full h-64 mb-4">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={chartData} layout="vertical" margin={{ right: 40 }}>
                            <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} />
                            <XAxis type="number" hide={true} />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar dataKey="count" fill="#8884d8" barSize={45}>
                                <LabelList dataKey="count" content={<CustomizedLabel />}/>
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <p className="text-center">No data available.</p>
            )}
            <h3 className="text-lg text-center font-semibold">Total Players: {totalUsers}</h3>
        </div>
    );
};

export default GlobalStatsChart;