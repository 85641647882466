// Userid.js
import { Preferences } from '@capacitor/preferences';


const generateUserId = async () => {
    const randomPart = Array(9).fill()
        .map(() => Math.random().toString(36).charAt(2))
        .join('');
    const newId = `userid-${randomPart}`;
    return newId;
};

const getStoredUserId = async () => {
    try {
        // Try to get username first
        const { value: username } = await Preferences.get({ key: 'username' });
        if (username) {
            return username;
        }

        // Try to get userid
        const { value: id } = await Preferences.get({ key: 'userid' });
        if (id && id.startsWith('userid-')) return id;

        // If no valid ID found, generate a new one
        const newId = await generateUserId();
        
        // Store the new ID
        await Preferences.set({
            key: 'userid',
            value: newId
        });

        return newId;
    } catch (e) {
        // If any unexpected error occurs, return a fresh ID
        return generateUserId();
    }
};

export { getStoredUserId };